import * as React from "react";
import Modal from "@cloudscape-design/components/modal";
import { Box, Button, SpaceBetween } from "@cloudscape-design/components";
import AccountView from "./AccountView";
import { useNavigate } from "react-router-dom";
import Account, { AccountFromJson } from "../interfaces/Account";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import getAuthenticated from "../request";
import { useErrorContext } from "../ErrorContext";
import { useOktaAuth } from '@okta/okta-react';

export default function AccountPeep({ userId }: { userId: string }) {
    // const [visible, setVisible] = React.useState(false);
    const [user, setUser] = React.useState<Account | null>(null);
    // const navigate = useNavigate()
    const { setError }= useErrorContext()
    const { oktaAuth:auth } = useOktaAuth()

    React.useEffect(() => {
        getAuthenticated(`/api/account/${userId}`, auth).then(res => {
            if (res) {
                const account = AccountFromJson(res.data)
                setUser(account)
            }
        }, err => {
            setError(err)
        })
    }, [userId])

    return (
        <>
            {user ?
                <Link to={`/account-detail/${userId}`}><Button data-banned={user?.is_banned}
                    iconName="expand"
                    iconSvg={<FontAwesomeIcon icon={faCircleUser} />}
                >
                    {user?.username || userId}</Button></Link>
                : <div className="skeleton" style={{ width: 200, height: 34 }}></div>
            }



            {/* deleting the modal for now */}
            {/* <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => navigate(`/account-detail/${userId}`)}>View More</Button>
                </SpaceBetween>
                </Box>
            }
        >
            <AccountView userId={userId} setUser={setUser}/>
        </Modal> */}
        </>
    );
}