import { Box, Header, Pagination, TextFilter } from "@cloudscape-design/components";
import Table from "@cloudscape-design/components/table";
import { useEffect, useState, useRef } from "react";
import getAuthenticated from "../request";
import { useErrorContext } from "../ErrorContext";
import { useOktaAuth } from '@okta/okta-react';

const PAGE_SIZE = 100

const columnDefinitions: any = [
                
    {
        id: 'file',
        header: 'File',
        sortingComparator: (a: any, b: any) => {
            return a._doc.file.localeCompare(b._doc.file);
        },
        cell: (item: any) => {
            return item._doc.file
        }
    },{
        id: 'key',
        header: 'Key',
        sortingComparator: (a: any, b: any) => {
            return a._doc.key.localeCompare(b._doc.key);
        },
        cell: (item: any) => {
            return item._doc.key
        }
    },{
        id: 'value',
        header: 'Value',
        sortingComparator: (a: any, b: any) => {
            return a._doc.value.localeCompare(b._doc.value);
        },
        cell: (item: any) => {
            return item._doc.value
        }
    }
]

export default function StringSearch() {
  const [search, setSearch] = useState("")
  const [strings, setStrings] = useState([])
  const [loading, setLoading] = useState(false)
  const abortController = useRef(new AbortController())
  const { oktaAuth:auth } = useOktaAuth()
  const { setError }= useErrorContext()

  useEffect(() => {
    if(search.length > 0) {
        if(!loading) setLoading(true)
        abortController.current.abort()
        abortController.current = new AbortController()
        getAuthenticated(`/api/strings`, auth, { params: { q: search }, signal: abortController.current.signal }).then(res => {
            if(res) {
                setStrings(res.data.RESULT)
                setLoading(false)
            }
        }, err => {
            // Ignore abort errors since we generate those when the user types
            if(err?.code !== "ERR_CANCELED") {
                setLoading(false)
                setError(err.message)
            }
        })
    }
    }, [search])

    const filter = <TextFilter
        filteringText={search}
        filteringPlaceholder={`Search Strings. To filter a specific column, use "column:value"`}
        onChange={({detail}) => setSearch(detail.filteringText)}
    />

    return <StringSearchTable rows={strings} filter={filter} loading={loading}/>
}

function StringSearchTable({ rows, filter, loading }: { rows: any[], filter: React.ReactNode, loading: boolean }) {
    const [sortedColumn, setSortedColumn] = useState(columnDefinitions[0])
    const [sortDescending, setSortDescending] = useState(false)
    const [page, setPage] = useState(1)

    if(!loading)
    rows = rows.sort((a, b) => {
        let result = sortedColumn.sortingComparator!(a, b)
        if(sortDescending) result *= -1
        return result
    })
    
    const pagination = <Pagination
        currentPageIndex={page}
        pagesCount={Math.ceil(rows.length / PAGE_SIZE)}
        ariaLabels={{
            nextPageLabel: "Next page",
            previousPageLabel: "Previous page",
            pageLabel: pageNumber =>
                `Page ${pageNumber} of all pages`
        }}
        onChange={({detail}) => {
            setPage(detail.currentPageIndex)
        }}
    />
    
    // slice down results to the current page
    const paginatedRows = rows.length > page * PAGE_SIZE 
        ? rows.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE) 
        : rows.slice(Math.max(0, rows.length - PAGE_SIZE), rows.length)

    return (
        <Table
            columnDefinitions={columnDefinitions}
            items={paginatedRows}
            loadingText="Loading resources"
            stickyHeader
            loading={loading}
            trackBy="id"
            onSortingChange={(event) => {
                setSortedColumn(event.detail.sortingColumn)
                setSortDescending(event.detail.isDescending!)
            }}
            sortingColumn={sortedColumn}
            sortingDescending={sortDescending}
            empty={
                <Box textAlign="center" color="inherit">
                    <b>None Found</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        Nothing matched your search. <br/> Due to quirks of indexing, make sure search is <strong>at least three characters</strong> and search is <strong>ten words or less</strong>.
                    </Box>
                </Box>
            }
            filter={filter}
            pagination={pagination}
        />
    )
}