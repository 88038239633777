import { Box, Button, ButtonDropdown, Form, FormField, Header, Input, SpaceBetween, Spinner, Checkbox } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import getAuthenticated from "../request";
import AccountPeep from "./AccountPeep";
import AccountView from "./AccountView";
import { Link } from "react-router-dom";
import { useErrorContext } from "../ErrorContext";
import { useOktaAuth } from '@okta/okta-react';
import { url } from "inspector";

const SEARCH_TYPES = [
    {
        "name": "Username",
        "id": "username",
        "hint": "Partial matches allowed",
        "quickSearch": "u"
    },
    {
        "name": "Email",
        "id": "email",
        "hint": "* wildcard allowed",
        "quickSearch": "e"
    },
    {
        "name": "IP Address",
        "id": "ip",
        "hint": "* wildcard allowed",
        "quickSearch": "i"
    },
    {
        "name": "GUID",
        "id": "guid",
        "hint": "Partial matches allowed",
        "quickSearch": "g"
    },
    {
        "name": "Station ID",
        "id": "station_id",
        "hint": "Exact matches only",
        "quickSearch": "s"
    },
    {
        "name": "Discord Snowflake",
        "id": "discord",
        "hint": "(not username) - exact matches only",
        "quickSearch": "d"
    },
    {
        "name": "Character First Name",
        "id": "character",
        "hint": "* wildcard allowed",
        "quickSearch": "c"
    }
]


export default function AccountFinder() {
    const [search, setSearch] = useState("")
    const [type, setType] = useState(SEARCH_TYPES[0])
    const [loading, setLoading] = useState(false)
    const [searchResponse, setSearchResponse] = useState<any>(null)
    const { oktaAuth: auth } = useOktaAuth()
    const { setError } = useErrorContext()
    const [exact, setChecked] = useState(false)

    useEffect(() => {
        let initialType: any = null;
        let initialSearch = null;

        const urlParams = new URLSearchParams(window.location.search);
        urlParams.forEach((value, key) => {
            if (key === "type") {
                const type = SEARCH_TYPES.find((item) => item.id === value);
                const quickType = SEARCH_TYPES.find((item) => item.quickSearch === value);

                initialType = type || quickType;
            }
            if (key === "q") {
                initialSearch = value;
            }
        });

        if (initialType) {
            setType(initialType);
        }
        if (initialSearch) {
            setSearch(initialSearch);
        }

        if (initialType && initialSearch) {
            setLoading(true);
            getAuthenticated(`/api/account`, auth, { params: { [initialType.id]: initialSearch } })
                .then(res => {
                    if (res) {
                        setSearchResponse(res.data);
                    }
                })
                .catch(err => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    return <>
        <form className="wide-container" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
            <Form
                actions={<Button variant="primary" disabled={loading} onClick={() => {
                    if (search.length > 0) {
                        setLoading(true)
                        getAuthenticated(`/api/account`, auth, { params: { [type.id]: search, exact: exact } }).then(res => {
                            if (res) {
                                setSearchResponse(res.data)
                                setLoading(false)
                            }
                        }, err => {
                            setError(err)
                            setLoading(false)
                        })
                    }
                }}>Search</Button>}
            >
                <SpaceBetween direction="vertical" size="l">
                    <FormField>
                        <ButtonDropdown
                            items={SEARCH_TYPES.map((item) => {
                                return {
                                    id: item.id,
                                    text: item.name,
                                    disabled: false
                                }
                            })}
                            onItemClick={({ detail }) => {
                                setType(SEARCH_TYPES.find((item) => item.id === detail.id)!)
                            }}
                        >
                            Search Type: {type.name}
                        </ButtonDropdown>
                    </FormField>
                    <FormField label={type.name} description={type.hint}>
                        <SpaceBetween direction="vertical" size="s">
                            <Input
                                onChange={({ detail }) => setSearch(detail.value)}
                                value={search}
                                placeholder={type.name}
                                autoFocus
                                type="search"
                            />
                            <Checkbox
                                onChange={({ detail }) =>
                                    setChecked(detail.checked)
                                }
                                checked={exact}
                            >
                                Exact match
                            </Checkbox>
                        </SpaceBetween>
                    </FormField>
                </SpaceBetween>
            </Form>
        </form>
        {loading || searchResponse && <Header>Results</Header>}
        {loading && <Spinner size="large" />}
        {searchResponse && <ResultsList users={searchResponse} />}
    </>
}

function ResultsList({ users }: { users: string[] }) {
    return <Box>

        {users.length === 0 && <div>No results found</div>}
        {users.length === 1 && <>
            <AccountView userId={users[0].toString()} />
            <Link to={`/account-detail/${users[0]}`}><Button>View More</Button></Link>
        </>
        }
        {users.length > 1 && <>
            <Box margin={{ bottom: 's' }}>{users.length} results found {users.length === 10 && '(further results may be truncated)'}</Box>
            <SpaceBetween direction="vertical" size="s">
                {users.map((userId: string) => <AccountPeep key={userId} userId={userId} />)}
            </SpaceBetween></>
        }
    </Box>
}