import { Box, Button, Form, FormField, Header, Input, SpaceBetween, Spinner, Table } from "@cloudscape-design/components";
import { useState } from "react";
import getAuthenticated from "../request";
import { useErrorContext } from "../ErrorContext";
import { useOktaAuth } from '@okta/okta-react';

export default function ObjectLookup() {
    const [oid, setOID] = useState("")
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState<Object | null>(null)
    const { oktaAuth:auth } = useOktaAuth()
    const { setError }= useErrorContext()

    return <>
        <form className="wide-container" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
            <Form
                actions={<Button variant="primary" disabled={loading} onClick={() => {
                    if(oid.length > 0) {
                        setLoading(true)
                        getAuthenticated(`/api/object/lookup/${oid}`, auth).then(res => {
                            setResponse(res.data)
                            setLoading(false)
                        }, err => {
                            setError(err)
                            setLoading(false)
                        })
                    }
                }}>Search</Button>}
                >
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label='Object ID' description='Object ID to lookup'>
                            <Input
                                onChange={({ detail }) => setOID(detail.value)}
                                value={oid}
                                autoFocus
                                type="search"
                                />
                        </FormField>
                    </SpaceBetween>
            </Form>
        </form>
        { loading || response && <Header>Results</Header> }
        { loading && <Spinner size="large" />}
        {response && <ResultsList object={response} />}
    </>
}

function ResultsList({object}: {object: Object}) {
    const columnDefinitions: any = [
        {
            id: 'key',
            header: 'Column',
            description: 'Column',
            cell: (o: any) => <span>{o.key}</span>
        },
        {
            id: 'value',
            header: 'Value',
            description: 'Value',
            cell: (o: any) => <span>{o.value}</span>
        }

    ]

    const list: any = []
    for(const [key, value] of Object.entries(object)) {
        list.push({key, value})
    }

    return <Table
        items={list}
        columnDefinitions={columnDefinitions}
    />
}