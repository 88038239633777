import { Alert, Box, Button, ButtonDropdown, Form, FormField, Header, Input, SpaceBetween, Spinner, Table, Textarea } from "@cloudscape-design/components";
import { useState } from "react";
import getAuthenticated from "../request";
import { useErrorContext } from "../ErrorContext";
import { Grant, GrantsFromJson } from "../interfaces/Fortitude";
import FortitudeGrantsTable from "./FortitudeGrantsTable";
import { useOktaAuth } from '@okta/okta-react';

interface Validation {
    reason?: string;
    amount?: string;
}

export default function GrantFortitude() {
  const [username, setUsername] = useState("")
  const [reason, setReason] = useState("")
  const [amount, setAmount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [grants, setGrants] = useState<Grant[] | null>(null)
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [validationError, setValidationError] = useState<Validation | null>(null)
  const { oktaAuth:auth } = useOktaAuth()
  const { setError }= useErrorContext()

    return <>
        {successMessage && <Alert type="success" dismissible onDismiss={() => setSuccessMessage("")}>{successMessage}</Alert>}
        <form onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
            <Form
                actions={<SpaceBetween direction="horizontal" size="s">
                    <Button disabled={username.length == 0} onClick={() => {
                        setLoading(true)
                        getAuthenticated(`/api/fortitude/list`, auth, { params: { username } }).then(res => {
                            if(res) {
                                setGrants(GrantsFromJson(res.data))
                                setLoading(false)
                            }
                        }, err => {
                            setError(err)
                            setLoading(false)
                        })
                    }}>Fetch Previous Grants</Button>
                    <Button variant="primary" disabled={grants == null} onClick={() => {
                        setValidationError(null)
                        if(reason.length > 500) {
                            setValidationError({reason: "Reason must be less than 500 characters"})
                            return
                        }
                        if(amount <= 0) {
                            setValidationError({amount: "Amount must be greater than 0"})
                            return
                        }
                        if(username.length > 0 && reason.length > 0 && amount > 0) {
                            setLoading(true)
                            getAuthenticated(`/api/fortitude/grant`, auth, { params: { username, reason, amount } }).then(res => {
                                setGrants([...grants!, { grant_reason: reason, amount, is_claimed: false, grant_date: new Date()}])
                                setLoading(false)
                                setSuccessMessage(`Grant to ${username} Successful`)
                            }, err => {
                                setError(err)
                                setLoading(false)
                            })
                        }
                    }}>Grant tokens</Button>
                    </SpaceBetween>}
                >
                    <SpaceBetween direction="vertical" size="s">
                        <FormField label="Username">
                            <Input
                                onChange={({ detail }) => setUsername(detail.value)}
                                value={username}
                                placeholder={"Account Username"}
                                autoFocus
                                type="search"
                            />
                        </FormField>
                        <FormField label="Reason" errorText={validationError?.reason}>
                            <Textarea
                                onChange={({ detail }) => setReason(detail.value)}
                                value={reason}
                                placeholder={"Reason for Grant"}
                            />
                        </FormField>
                        <FormField label="Amount" errorText={validationError?.amount}>
                            <Input
                                onChange={({ detail }) => setAmount(parseInt(detail.value))}
                                value={`${amount}`}
                                placeholder={"Amount of Fortitude"}
                                type="number"
                            />
                        </FormField>
                    </SpaceBetween>
            </Form>
        </form>
        { loading 
            ? <Spinner size="large"/>
            : <SpaceBetween direction="vertical" size="l">
                <Header variant="h2">Previous Grants</Header>
                {grants != null && <FortitudeGrantsTable grants={grants} loading={loading} />} 
            </SpaceBetween>
        }

    </>
}
