type JediEnum = 0 | 1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | 1024
export type JediEnumValue = "None" | "Phase One Complete" | "Phase Two Complete" | "In Village / Force Sensitive" | "Village Complete" | "Jedi Initiate Quests Started" | "Jedi Initiate Quests Completed" | "In Padawan Trials" | "Padawan Trials Complete" | "Light Jedi" | "Dark Jedi" | "Grey Jedi"

export interface JediStats {
    OID: string,
    value: JediEnumValue
}
export function JediStatsFromJson(json: { OID: string, Value: JediEnum}[]): JediStats[] {
    return json.map(j => { return {
        OID: j.OID,
        value: JediStatEnumValues[j.Value] as JediEnumValue
    }})
}

export const JediStatEnumValues = {
    0: "None",
    1: "Phase One Complete",
    2: "Phase Two Complete",
    4: "In Village / Force Sensitive",
    8: "Village Complete",
    16: "Jedi Initiate Quests Started",
    32: "Jedi Initiate Quests Completed",
    64: "In Padawan Trials",
    128: "Padawan Trials Complete",
    256: "Light Jedi",
    512: "Dark Jedi",
    1024: "Grey Jedi"
}