
export interface Character {
    character_full_name: string;
    last_login_time?: Date;
    create_time?: Date;
    creature_object: string;
    player_object: string;
    deleted_date?: Date;
    character_first_name: string;
}


export const CharactersFromJson = (json: any[]): Character[] => {
    return json?.map((character: Character) => {
        return {
            character_full_name: character.character_full_name,
            last_login_time: parseDate(character.last_login_time),
            create_time: parseDate(character.create_time),
            creature_object: character.creature_object,
            player_object: character.player_object,
            deleted_date: parseDate(character.deleted_date),
            character_first_name: character.character_full_name.split(" ")[0]
        }
    }) || []
}


const parseDate = (date: any) => {
    if(date && date !== "1970-01-01T00:00:00Z") {
        return new Date(date)
    }
    return undefined
}