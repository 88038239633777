import { Box, StatusIndicator } from "@cloudscape-design/components";

export function FieldValue({field, value}: {field: string, value: string}) {
    return <FieldValueElement field={field} value={
        <div>{value && (value + "").length > 0 ? value : '-'}</div>
    } />
}

export function FieldBoolean({field, value, status, variant = "normal"}: {field: string, value: boolean, status: string, variant?: string}) {
    const type = 
        variant === 'red'
            ? value? 'error' : 'success'
            : value ? 'success' : "stopped"
    return <FieldValueElement field={field} value={
        <StatusIndicator type={type}>{status}</StatusIndicator>
    } />
}

export function FieldValueElement({field, value}: {field: string, value: JSX.Element}) {
    return <Box margin={{bottom: 'm'}}>
        <div style={{"fontWeight": "bold"}}>{field}</div>
        {value}
    </Box>
}