import React, { useEffect, useState } from 'react';
import { Input, Select, Button, SpaceBetween, Container, Modal, Box, Checkbox, Link } from '@cloudscape-design/components';
import { Patch, patchesFromJSON, Note, NoteType, NoteStatus, SubNote, NoteCategory, categoryToImage } from '../interfaces/Patch';
import getAuthenticated, { postAuthenticated, putAuthenticated, deleteAuthenticated } from "../request";
import { useOktaAuth } from '@okta/okta-react';
import { useErrorContext } from "../ErrorContext";
import { v4 as uuidv4 } from 'uuid';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import menu from '../menu.json'

const PatchNoteTool: React.FC = () => {
    const [patches, setPatches] = useState<Patch[]>([]);
    const [loading, setLoading] = useState(true);
    const [newPatchTitle, setNewPatchTitle] = useState('');

    const [patchVisible, setPatchVisible] = React.useState(false);
    const [noteVisible, setNoteVisible] = React.useState(false);
    const [subnoteVisible, setSubnoteVisible] = React.useState(false);
    const [deleteIds, setDeleteIds] = React.useState({ patchId: '', noteId: '', subNoteId: '' });

    type CollapsedStates = {
        [key: string]: boolean;
    };

    const [collapsedStates, setCollapsedStates] = useState<CollapsedStates>({});

    const toggleCollapsedState = (patchId: string, noteId: string) => {
        const key = `${patchId}:${noteId}`;
        setCollapsedStates(prevStates => ({
            ...prevStates,
            [key]: !(prevStates[key] ?? true)
        }));
    };

    const { oktaAuth: auth } = useOktaAuth()
    const setError = useErrorContext().setError

    useEffect(() => {
        getAuthenticated('/api/patch/', auth).then((response) => {
            setLoading(false)
            setPatches(patchesFromJSON(response.data));
        }, err => {
            setLoading(false)
            setError(err.message)
        });
    }, []);

    const addPatch = (patchName: string) => {
        if (patchName === '') return setError('Patch name cannot be empty');
        const newPatch: Patch = {
            _id: uuidv4(),
            title: patchName,
            notes: []
        };
        setPatches([...patches, newPatch]);
        postAuthenticated('/api/patch/', newPatch, auth).then((response) => {
            setNewPatchTitle('');
        }, err => {
            setError(err.message)
        });
    };

    const deletePatch = (patchId: string) => {
        setPatches(patches.filter(patch => patch._id !== patchId));
        deleteAuthenticated('/api/patch/' + patchId, auth).then((response) => {
        }, err => {
            setError(err.message)
        });
    };

    function isValidUrl(urlString: string) {
        try {
            new URL(urlString);
            return true;
        } catch (e) {
            return false;
        }
    }

    const addPatchNote = (patchId: string, type: NoteType, content: string, category: NoteCategory, senatePriority: boolean, playerVoice: boolean, playerVoiceURL: string) => {
        if (content === '') return setError('Note content cannot be empty');
        if (playerVoice && playerVoiceURL === '') return setError('Player voice URL cannot be empty');
        if (playerVoice && !isValidUrl(playerVoiceURL)) return setError('Player voice URL is invalid');

        let patchToSend;
        const newNote: Note = {
            _id: uuidv4(),
            type,
            content,
            status: NoteStatus.Pending,
            subNotes: [],
            category,
            senatePriority,
            playerVoice,
            playerVoiceURL
        };
        setPatches(patches.map(patch => {
            if (patch._id === patchId) {
                patchToSend = {
                    ...patch,
                    notes: [...patch.notes, newNote]
                }
                return patchToSend
            }
            return patch;
        }));
        putAuthenticated('/api/patch/', patchToSend, auth).then((response) => {
        }, err => {
            setError(err.message)
        });
    };

    const toggleNoteStatus = (patchId: string, noteId: string) => {
        let patchToSend;
        setPatches(patches.map(patch => {
            if (patch._id === patchId) {
                patchToSend = {
                    ...patch,
                    notes: patch.notes.map(note => {
                        if (note._id === noteId) {
                            return {
                                ...note,
                                status: note.status === NoteStatus.Approved ? NoteStatus.Pending : NoteStatus.Approved
                            };
                        }
                        return note;
                    })
                };
                return patchToSend;
            }
            return patch;
        }));
        putAuthenticated('/api/patch/', patchToSend, auth).then((response) => {
        }, err => {
            setError(err.message)
        });
    };

    const deletePatchNote = (patchId: string, patchNoteId: string) => {
        let patchToSend;
        setPatches(patches.map(patch => {
            if (patch._id === patchId) {
                patchToSend = {
                    ...patch,
                    notes: patch.notes.filter(note => note._id !== patchNoteId)
                };
                return patchToSend;
            }
            return patch;
        }));
        putAuthenticated('/api/patch/', patchToSend, auth).then((response) => {
        }, err => {
            setError(err.message)
        });
    };

    const addSubNote = (patchId: string, patchNoteId: string, content: string) => {
        if (content === '') return setError('Subnote content cannot be empty');
        let patchToSend;
        const newSubNote: SubNote = {
            _id: uuidv4(),
            content
        };
        setPatches(patches.map(patch => {
            if (patch._id === patchId) {
                patchToSend = {
                    ...patch,
                    notes: patch.notes.map(note => {
                        if (note._id === patchNoteId) {
                            return {
                                ...note,
                                subNotes: [...note.subNotes, newSubNote]
                            };
                        }
                        return note;
                    })
                };
                return patchToSend;
            }
            return patch;
        }));
        putAuthenticated('/api/patch/', patchToSend, auth).then((response) => {
        }, err => {
            setError(err.message)
        });
    };

    const deleteSubNote = (patchId: string, patchNoteId: string, subNoteId: string) => {
        let patchToSend;
        setPatches(patches.map(patch => {
            if (patch._id === patchId) {
                patchToSend = {
                    ...patch,
                    notes: patch.notes.map(note => {
                        if (note._id === patchNoteId) {
                            return {
                                ...note,
                                subNotes: note.subNotes.filter(subNote => subNote._id !== subNoteId)
                            };
                        }
                        return note;
                    })
                };
                return patchToSend;
            }
            return patch;
        }));
        putAuthenticated('/api/patch/', patchToSend, auth).then((response) => {
        }, err => {
            setError(err.message)
        });
    };

    /*Title:
    ```[patch_note_top=Publish 1.2: The Schisms of The Heretics]Patch Notes[/patch_note_top]```
    Header:
    ```[patch_note_header=https://swgr.org/images/patch/community.png]Community[/patch_note_header]```
    Regular note:
    ```[*][changed]The base regen rate for Health has been increased: 1% of your max health per second out of combat; 0.25% of your max health per second in combat.[/changed]```
    Senate:
    ```[*][added]Daily Login Rewards are rewards you receive just for logging in every day. The time of day resets at midnight Greenwich Mean Time Zone.[/added][patch_note_senate]x[/patch_note_senate]```
    PV:
    ```[*][patch_note_pv=https://swgr.org/post/update-no-trade-shared-to-allow-placing-item-in-alt-or-main-chars-house-instead-of-their-own.727/][changed]Update No Trade Shared to Allow Placing Item in Alt or Main Char's House Instead of their Own.[/changed][/patch_note_pv]```*/

    /*Format:
    -Category (alphabetical order)
    --Patch Note
    ---Subnote*/

    /*How to list bullets and subbullets in BBCode:
    [list]
    [*] Item 1
    [*] Item 2
    [list]
    [*] Subitem 1
    [*] Subitem 2
    [/list]
    [*] Item 3
    [/list]*/

    const exportPatchToBBCode = (patch: Patch) => {
        let bbCode = `[patch_note_top=${patch.title}]Patch Notes[/patch_note_top]\n`;
        const categories = Object.values(NoteCategory).sort();
        categories.forEach(category => {
            const notes = patch.notes.filter(note => (note.category === category) && (note.status === NoteStatus.Approved));
            if (notes.length > 0) {
                const imageName = categoryToImage[category] || 'default.png';
                bbCode += `[patch_note_header=https://swgr.org/images/patch/${imageName}]${category}[/patch_note_header]\n`;
                bbCode += `[list]\n`;
                notes.forEach(note => {
                    if (note.playerVoice) {
                        bbCode += `[*][patch_note_pv=${note.playerVoiceURL}][${note.type.toLowerCase()}]${note.content}[/${note.type.toLowerCase()}][/patch_note_pv]`;
                    } else {
                        bbCode += `[*][${note.type.toLowerCase()}]${note.content}[/${note.type.toLowerCase()}]`;
                    }
                    if (note.senatePriority) {
                        bbCode += `[patch_note_senate]x[/patch_note_senate]`;
                    }
                    if (note.subNotes.length > 0) {
                        bbCode += `\n[list]\n`;
                        note.subNotes.forEach(subNote => {
                            bbCode += `[*]${subNote.content}\n`;
                        });
                        bbCode += `[/list]\n`;
                    } else {
                        bbCode += '\n';
                    }
                });
                bbCode += `[/list]\n`;
            }
        });
        navigator.clipboard.writeText(bbCode);
    };

    const PatchNoteCategory: React.FC<{ patch: Patch, category: NoteCategory, collapsed: boolean, toggleCollapsed: (patchId: string, category: string) => void }> = ({ patch, category, collapsed, toggleCollapsed }) => {
        if (!(`${patch._id}:${category}` in collapsedStates)) {
            toggleCollapsed(patch._id, category);
            toggleCollapsed(patch._id, category);
        }

        return (
            <div style={{ borderBottom: "1px solid white" }}>
                <SpaceBetween direction="horizontal" size="s">
                    <h3 onClick={() => toggleCollapsed(patch._id, category)} style={{ cursor: "pointer" }}>{category}</h3>
                    {collapsed ? <h3 onClick={() => toggleCollapsed(patch._id, category)} style={{ cursor: "pointer" }}>+</h3> : <h3 onClick={() => toggleCollapsed(patch._id, category)} style={{ cursor: "pointer" }}>-</h3>}
                </SpaceBetween>
                {collapsed ? null : <div style={{ marginLeft: "4em" }}>
                    <PatchNoteInput patch={patch} category={category} />
                </div>}
                {collapsed ? null : patch.notes.filter(note => note.category === category).map(note => {
                    if (!(`${patch._id}:${note._id}` in collapsedStates)) {
                        collapsedStates[`${patch._id}:${note._id}`] = true;
                    }
                    return (<PatchNote patch={patch} note={note} collapsed={collapsedStates[`${patch._id}:${note._id}`]} toggleCollapsed={toggleCollapsedState} />)
                })}
            </div>)
    };

    const PatchNoteInput: React.FC<{ patch: Patch, category: NoteCategory }> = ({ patch, category }) => {
        const [newNoteContent, setNewNoteContent] = useState('');
        const [newNoteType, setNewNoteType] = useState<{ label: string, value: NoteType }>({ label: NoteType.Added, value: NoteType.Added });
        const [senatePriority, setSenatePriority] = useState(false);
        const [playerVoice, setPlayerVoice] = useState(false);
        const [playerVoiceURL, setPlayerVoiceURL] = useState('');

        return (
            <SpaceBetween direction="vertical" size="s">
                <SpaceBetween direction="horizontal" size="s">
                    <Input
                        value={newNoteContent}
                        placeholder='New note...'
                        onChange={({ detail }) => setNewNoteContent(detail.value)}
                    />
                    <Select
                        selectedOption={newNoteType}
                        options={Object.values(NoteType).map(type => ({ label: type, value: type }))}
                        onChange={({ detail }) => setNewNoteType(detail.selectedOption as { label: string, value: NoteType })}
                    />
                    <Button onClick={() => { addPatchNote(patch._id, newNoteType.value, newNoteContent, category, senatePriority, playerVoice, playerVoiceURL); setNewNoteContent(''); }}>Add Note</Button>
                </SpaceBetween>
                <SpaceBetween direction="horizontal" size="s">
                    <Checkbox checked={senatePriority} onChange={() => setSenatePriority(!senatePriority)} >
                        Senate Priority
                    </Checkbox>
                    <Checkbox checked={playerVoice} onChange={() => setPlayerVoice(!playerVoice)} >
                        Player Voice
                    </Checkbox>
                </SpaceBetween>
                {playerVoice && <SpaceBetween direction="horizontal" size="s">
                    <Input
                        value={playerVoiceURL}
                        placeholder='Player voice URL...'
                        onChange={({ detail }) => setPlayerVoiceURL(detail.value)}
                    />
                </SpaceBetween>}
                {patch.notes.filter(note => note.category === category).length === 0 && <br />}
            </SpaceBetween>
        );
    };

    const SubNoteInput: React.FC<{ patch: Patch, note: Note }> = ({ patch, note }) => {
        const [newSubNoteContent, setNewSubNoteContent] = useState('');

        return (<>
            <SpaceBetween direction="horizontal" size="s">
                <Input
                    value={newSubNoteContent}
                    placeholder='New subnote...'
                    onChange={({ detail }) => setNewSubNoteContent(detail.value)}
                />
                <Button onClick={() => { addSubNote(patch._id, note._id, newSubNoteContent); setNewSubNoteContent('') }}>Add Subnote</Button>
            </SpaceBetween>
        </>);
    };

    const PatchNote: React.FC<{ patch: Patch, note: Note, collapsed: boolean, toggleCollapsed: (patchId: string, noteId: string) => void }> = ({ patch, note, collapsed, toggleCollapsed }) => {

        return (<div style={{ marginLeft: "4em" }}>
            <h4 onClick={() => toggleCollapsed(patch._id, note._id)} style={{ cursor: "pointer" }}>
                {note.content + ": " + note.type + " (" + note.status + ")"} {note.playerVoice ? <Link external href={note.playerVoiceURL} >[PV]</Link> : null} {note.senatePriority ? ' [SP]' : null} {collapsed ? ' +' : ' -'}
            </h4>
            {collapsed ? null : <>
                <SpaceBetween direction="vertical" size="s" >
                    <div style={{ marginLeft: "4em" }}>
                        <SubNoteInput patch={patch} note={note} />
                        {note.subNotes.map(subNote => (<>
                            <span style={{ display: "inline-block", paddingTop: "0.5em" }}>{subNote.content} < FontAwesomeIcon icon={faTrash} onClick={() => { setDeleteIds({ patchId: patch._id, noteId: note._id, subNoteId: subNote._id }); setSubnoteVisible(true) }} style={{ cursor: 'pointer', marginLeft: '1em' }} /></span>
                            <br />
                        </>
                        ))}
                    </div>
                    <SpaceBetween direction="horizontal" size="s">
                        <Button onClick={() => toggleNoteStatus(patch._id, note._id)}>
                            {note.status === NoteStatus.Approved ? "Set to Pending" : "Approve"}
                        </Button>
                        <Button onClick={() => { setDeleteIds({ patchId: patch._id, noteId: note._id, subNoteId: '' }); setNoteVisible(true) }}>Delete Note</Button>
                    </SpaceBetween>
                </SpaceBetween>
                {patch.notes.filter(n => n.category === note.category).indexOf(note) === patch.notes.filter(n => n.category === note.category).length - 1 ? <br /> : null}
            </>}
        </div>)
    }

    return (<>
        <Modal
            onDismiss={() => setPatchVisible(false)}
            visible={patchVisible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="s">
                        <Button variant="link">Cancel</Button>
                        <Button variant="primary" onClick={() => { deletePatch(deleteIds.patchId); setPatchVisible(false) }}>DELETE</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Really delete patch?"
        />
        <Modal
            onDismiss={() => setNoteVisible(false)}
            visible={noteVisible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="s">
                        <Button variant="link">Cancel</Button>
                        <Button variant="primary" onClick={() => { deletePatchNote(deleteIds.patchId, deleteIds.noteId); setNoteVisible(false) }}>DELETE</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Really delete note?"
        />
        <Modal
            onDismiss={() => setSubnoteVisible(false)}
            visible={subnoteVisible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="s">
                        <Button variant="link">Cancel</Button>
                        <Button variant="primary" onClick={() => { deleteSubNote(deleteIds.patchId, deleteIds.noteId, deleteIds.subNoteId); setSubnoteVisible(false) }}>DELETE</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Really delete subnote?"
        />
        <SpaceBetween direction="horizontal" size="s">
            <Input value={newPatchTitle} placeholder='Title of your new patch...' onChange={({ detail }) => setNewPatchTitle(detail.value)} />
            <Button onClick={() => addPatch(newPatchTitle)}>Add New Patch</Button>
        </SpaceBetween>
        <br />
        {patches.length === 0 && !loading && <p>No patches found. Create one above!</p>}
        {loading && <p>Fetching patch notes...</p>}
        {patches.map(patch => (<>
            <Container key={patch._id}>
                <h2>{patch.title}</h2>
                <div style={{ marginLeft: "4em" }}>
                    <PatchNoteCategory patch={patch} category={NoteCategory.BadgesAndCollections} collapsed={collapsedStates[`${patch._id}:${NoteCategory.BadgesAndCollections}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.Client} collapsed={collapsedStates[`${patch._id}:${NoteCategory.Client}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.Combat} collapsed={collapsedStates[`${patch._id}:${NoteCategory.Combat}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.Community} collapsed={collapsedStates[`${patch._id}:${NoteCategory.Community}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.Content} collapsed={collapsedStates[`${patch._id}:${NoteCategory.Content}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.Crafting} collapsed={collapsedStates[`${patch._id}:${NoteCategory.Crafting}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.Events} collapsed={collapsedStates[`${patch._id}:${NoteCategory.Events}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.GCW} collapsed={collapsedStates[`${patch._id}:${NoteCategory.GCW}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.ItemsAndEquipment} collapsed={collapsedStates[`${patch._id}:${NoteCategory.ItemsAndEquipment}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.Misc} collapsed={collapsedStates[`${patch._id}:${NoteCategory.Misc}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.Space} collapsed={collapsedStates[`${patch._id}:${NoteCategory.Space}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.Professions} collapsed={collapsedStates[`${patch._id}:${NoteCategory.Professions}`]} toggleCollapsed={toggleCollapsedState} />
                    <PatchNoteCategory patch={patch} category={NoteCategory.UserInterface} collapsed={collapsedStates[`${patch._id}:${NoteCategory.UserInterface}`]} toggleCollapsed={toggleCollapsedState} />
                </div>
                <br />
                <SpaceBetween direction="horizontal" size="s">
                    <Button onClick={() => exportPatchToBBCode(patch)}>Copy BBCode to clipboard</Button>
                    <Button onClick={() => { setDeleteIds({ patchId: patch._id, noteId: '', subNoteId: '' }); setPatchVisible(true) }}>Delete Patch</Button>
                </SpaceBetween>
            </Container>
            <br />
        </>))}
    </>);
};

export default PatchNoteTool;