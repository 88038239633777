export interface GameObject {
    oid: string
    name: string
    template: string
    container: string
}

export const ObjectsFromJSON = (json: any): GameObject[] => {
    return json.map((object: any) => {
        return {
            oid: object.oid,
            name: object.name,
            template: object.template,
            container: object.container,
        }
    })
}