import { applyMode, Mode } from '@cloudscape-design/global-styles';
import { Box, SpaceBetween, Spinner } from "@cloudscape-design/components";
import React, { useEffect, useState, useRef, createContext, useContext } from "react";
import { useOktaAuth } from '@okta/okta-react';
import { AuthState, toRelativeUrl } from '@okta/okta-auth-js';
import getAuthenticated from "./request";

const AuthProvider = createContext<AuthState | null>(null);
export const useAuth = () => useContext(AuthProvider)
const UserRoleContext = createContext<string[] | undefined>(undefined);
export const useRoles = () => useContext(UserRoleContext)

const ADHD_USERS = ['ACONITE@SWGR.ORG']

export default function Secured({children}: {children: React.ReactNode}) {
  const [roles, setRoles] = useState<string[] | undefined>(undefined);
  const { oktaAuth, authState } = useOktaAuth();
  
  useEffect(() => {

    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      console.log('User is not authenticated, redirecting to login page...')
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }

    oktaAuth.tokenManager.on('renewed', (key, renewedToken) => {
      performUserCustomizations(authState);
    });

  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  // call role api to get roles
  useEffect(() => {
    if (!authState?.isAuthenticated) {
      return;
    }

    getAuthenticated('/api/roles', oktaAuth)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
  }, [authState?.isAuthenticated, oktaAuth])

  if (authState?.isAuthenticated) {
    performUserCustomizations(authState);
    return (
      <AuthProvider.Provider value={authState}>
        <UserRoleContext.Provider value={roles}>
          {children}
        </UserRoleContext.Provider>
      </AuthProvider.Provider>
    )
  } 

  return <Initializing/>
}

function Initializing() {
  const dark = localStorage.getItem('mode') === 'dark' ? true : false
  applyMode(dark ? Mode.Dark : Mode.Light)

  return <Box 
    fontSize="heading-l"
    fontWeight="light"
    textAlign="center"
    padding={{top: 'xxxl'}} >
      <SpaceBetween direction="vertical" size="l">
        <Spinner key="spinner" size="large" />
        <span>
          Authenticating...
        </span>
      </SpaceBetween>
    </Box>
}

function performUserCustomizations(okta: AuthState) {
  const username = okta.idToken?.claims?.preferred_username

  document.body.dataset.adhd = username && ADHD_USERS.includes(username.toUpperCase()) ? 'true' : 'false'
}