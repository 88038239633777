import { Alert, Box, Button, ButtonDropdown, Form, FormField, Header, Input, SpaceBetween, Spinner, Table, Textarea } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import getAuthenticated from "../request";
import { useErrorContext } from "../ErrorContext";
import { Grant, GrantsFromJson } from "../interfaces/Fortitude";
import FortitudeGrantsTable from "./FortitudeGrantsTable";
import { useOktaAuth } from '@okta/okta-react';

interface Validation {
    reason?: string;
    amount?: string;
}

export default function PendingFortitude() {
    const [loading, setLoading] = useState(true)
    const [grants, setGrants] = useState<Grant[] | null>(null)
    const { oktaAuth:auth } = useOktaAuth()
    const { setError }= useErrorContext()

    useEffect(() => {
        setLoading(true)
        getAuthenticated(`/api/fortitude/list`, auth).then(res => {
            if (res) {
                setGrants(GrantsFromJson(res.data))
                setLoading(false)
            }
        }, err => {
            setError(err)
            setLoading(false)
        })
    }, [])

    return <>
        <Header description="Unclaimed tokens for all players">Pending Fortitude Grants</Header>
        { loading && <Spinner size="large"/>}
        <Box margin={{ top: "l" }} >
            {grants != null && <FortitudeGrantsTable grants={grants} loading={loading} />} 
        </Box>

    </>
}
