import React, { useState } from 'react';
import DataTable from './DataTable';
import tableSources from '../tableSources.json';
import { Tabs } from '@cloudscape-design/components';

function DataTables() {

  const [activeTab, setActiveTab] = useState(tableSources[0].id)

  return (
      <Tabs
        activeTabId={activeTab}
        onChange={({detail}) => setActiveTab(detail.activeTabId)}
        tabs={tableSources.map((tableSource) => {
          return {
            id: tableSource.id,
            label: tableSource.name,
            content: <DataTable id={tableSource.id} type={tableSource.name} />
          }
        })}
      />
  );
}

export default DataTables;
