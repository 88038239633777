import { Character } from "./Character";

export default interface Account {
    is_banned: boolean;
    is_staff: boolean;
    email: string;
    username: string;
    discord: string;
    discord_key: string;
    register_date?: Date
    last_activity?: Date
    guids: string[];
    suid_real: string;
    suid_comp: string;
    live_god_level: string;
    test_god_level: string;
    user_state: string;
    tfa: boolean;
    receive_admin_email: boolean;
    security_lock: string;
    ban_date?: Date;
    end_date?: Date;
    user_reason?: string;
    identity_verification_status: string;
    identity_verification_attempts: number;
    identity_verification_hash: string;
}
export const AccountFromJson = (json: any): Account => {
    return {
        is_banned: json.is_banned,
        is_staff: json.is_staff,
        email: json.email,
        username: json.username,
        discord: json.discord,
        discord_key: json.discord_key,
        register_date: parseDate(json.register_date),
        last_activity: parseDate(json.last_activity),
        guids: json.guids ? json.guids.split(":") : [],
        suid_real: json.suid_real,
        suid_comp: json.suid_comp,
        live_god_level: json.live_god_level,
        test_god_level: json.test_god_level,
        user_state: json.user_state,
        tfa: json.tfa,
        receive_admin_email: json.receive_admin_email,
        security_lock: json.security_lock,
        ban_date: parseDate(json.ban_date),
        end_date: parseDate(json.end_date),
        user_reason: json.user_reason,
        identity_verification_status: json.identity_verification_status,
        identity_verification_hash: json.identity_verification_hash,
        identity_verification_attempts: json.identity_verification_attempts
    }
}

export interface WarningData {
    total_points: number;
    total_unexpired_points: number;
    warnings: Warning[];
}
export interface Warning{
    content_title: string;
    warning_date: Date;
    warning_user_id: string;
    title: string;
    notes: string;
    points: number;
    expiry_date: Date;
}
export const WarningDataFromJson = (json: any): WarningData => {
    return {
        total_points: json.total_points,
        total_unexpired_points: json.total_unexpired_points,
        warnings: json.warnings?.map((warning: any) => {
            return {
                content_title: warning.content_title,
                warning_date: parseDate(warning.warning_date),
                warning_user_id: warning.warning_user_id,
                title: warning.title,
                notes: warning.notes,
                points: warning.points,
                expiry_date: parseDate(warning.expiry_date)
            }   
        }) || []
    }
}

export interface SupportTicket {
    ticket_ref: string;
    title: string;
    status: string;
    start_date?: Date;
    ticket_id: string;
    username: string;
}
export const SupportTicketsFromJson = (json: any[]): SupportTicket[] => {
    return json?.map((ticket: SupportTicket) => {
        return {
            ticket_ref: ticket.ticket_ref,
            title: ticket.title,
            status: ticket.status,
            start_date: parseDate(ticket.start_date),
            ticket_id: ticket.ticket_id,
            username: ticket.username
        }
    }) || []
}

export interface Change {
    edit_date?: Date;
    field: string;
    old_value: string;
    new_value: string;
}
export const ChangesFromJson = (json: any[]): Change[] => {
    return json?.map((change: Change) => {
        return {
            edit_date: parseDate(change.edit_date),
            field: change.field,
            old_value: change.old_value,
            new_value: change.new_value
        }
    }) || []
}

export interface IPLog {
    ip: string;
    content_type: string;
    action: string;
    log_date?: Date;
}
export const IPsFromJson = (json: any[]): IPLog[] => {
    return json?.map((ip: IPLog) => {
        return {
            ip: ip.ip,
            content_type: ip.content_type,
            action: ip.action,
            log_date: parseDate(ip.log_date)
        }
    }) || []
}

const parseDate = (date: any) => {
    if(date && date !== "1970-01-01T00:00:00Z") {
        return new Date(date)
    }
    return undefined
}