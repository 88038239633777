import { Box, Button, Form, FormField, Header, Input, SpaceBetween, Spinner, Table } from "@cloudscape-design/components";
import { useState } from "react";
import getAuthenticated from "../request";
import { useErrorContext } from "../ErrorContext";
import { GameObject, ObjectsFromJSON } from "../interfaces/Object";
import { useOktaAuth } from '@okta/okta-react';

export default function AccountFinder() {
    const [oid, setOID] = useState("")
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState<GameObject[] | null>(null)
    const { oktaAuth:auth } = useOktaAuth()
    const { setError }= useErrorContext()

    return <>
        <form className="wide-container" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
            <Form
                actions={<Button variant="primary" disabled={loading} onClick={() => {
                    if(oid.length > 0) {
                        setLoading(true)
                        getAuthenticated(`/api/object/containment/${oid}`, auth).then(res => {
                            setResponse(ObjectsFromJSON(res.data))
                            setLoading(false)
                        }, err => {
                            setError(err)
                            setLoading(false)
                        })
                    }
                }}>Search</Button>}
                >
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label='Object ID' description='Object ID that exists in a container'>
                            <Input
                                onChange={({ detail }) => setOID(detail.value)}
                                value={oid}
                                autoFocus
                                type="search"
                                />
                        </FormField>
                    </SpaceBetween>
            </Form>
        </form>
        { loading || response && <Header>Results</Header> }
        { loading && <Spinner size="large" />}
        {response && <ResultsList list={response} />}
    </>
}

function ResultsList({list}: {list: GameObject[]}) {
    const columnDefinitions: any = [
        {
            id: 'OID',
            header: 'OID',
            description: 'Object ID',
            cell: (o: GameObject) => <span>{o.oid}</span>
        },
        {
            id: 'Name',
            header: 'Name',
            description: 'Object Name',
            cell: (o: GameObject) => <span>{o.name}</span>
        },
        {
            id: 'Template',
            header: 'Template',
            description: 'Object Template',
            cell: (o: GameObject) => <span>{o.template}</span>
        },
        {
            id: 'Container',
            header: 'Container',
            description: 'Object Container',
            cell: (o: GameObject) => <span>{o.container}</span>
        }

    ]

    return <Table
        items={list}
        columnDefinitions={columnDefinitions}
    />
}