export interface Patch {
    _id: string;
    title: string;
    notes: Note[];
}

export interface Note {
    _id: string;
    type: NoteType;
    category: NoteCategory;
    content: string;
    status: NoteStatus;
    subNotes: SubNote[];
    senatePriority: boolean;
    playerVoice: boolean;
    playerVoiceURL: string;
}

export enum NoteType {
    Added = "Added",
    Fixed = "Fixed",
    Changed = "Changed",
    Removed = "Removed"
}

export enum NoteStatus {
    Pending = "Pending",
    Approved = "Approved"
}

export enum NoteCategory {
    BadgesAndCollections = "Badges and Collections",
    Client = "Client",
    Combat = "Combat",
    Community = "Community",
    Content = "Content",
    Crafting = "Crafting",
    Events = "Events",
    GCW = "GCW",
    ItemsAndEquipment = "Items and Equipment",
    Misc = "Misc",
    Space = "Space",
    Professions = "Professions",
    UserInterface = "User Interface"
}

export enum categoryToImage {
    "Badges and Collections" = "badge.png",
    "Client" = "client.png",
    "Combat" = "combat.png",
    "Community" = "community.png",
    "Content" = "content.png",
    "Crafting" = "crafting.png",
    "Events" = "events.png",
    "GCW" = "gcw.png",
    "Items and Equipment" = "items.png",
    "Misc" = "misc.png",
    "Space" = "space.png",
    "Professions" = "professions.png",
    "User Interface" = "ui.png"
}

export interface SubNote {
    _id: string;
    content: string;
}

export function patchesFromJSON(json: any): Patch[] {
    return json.map((patch: any) => {
        return {
            _id: patch._id,
            title: patch.title,
            notes: patch.notes.map((note: any) => {
                return {
                    _id: note._id,
                    type: note.type,
                    senatePriority: note.senatePriority,
                    playerVoice: note.playerVoice,
                    playerVoiceURL: note.playerVoiceURL,
                    category: note.category,
                    content: note.content,
                    status: note.status,
                    subNotes: note.subNotes.map((subNote: any) => {
                        return {
                            _id: subNote._id,
                            content: subNote.content
                        }
                    })
                }
            })
        }
    });
}