import React from "react";
import { Table, Link, TextContent, Header } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import getAuthenticated from "../request";
import { useErrorContext } from "../ErrorContext";
import { useOktaAuth } from '@okta/okta-react';

export default function MultiAccountReport() {
    const [loading, setLoading] = useState(true)
    const { oktaAuth: auth } = useOktaAuth()
    const { setError } = useErrorContext()
    const [expandedItems, setExpandedItems] = useState<any[]>([]);

    interface XenForoUser {
        user_id: number
        username: string
        user_state: string
        verification_status: string
        is_banned: boolean
        shared_ip_addresses: string[]
    }

    interface TableEntry extends XenForoUser {
        secondary_users: TableEntry[];
    }

    interface MultiAccountReport {
        users: { primary_user: XenForoUser, secondary_users: XenForoUser[] }[]
        ban_evasion: { banned_users: XenForoUser[], unbanned_users: XenForoUser[] }[]
        verification_tracking: { verified_users: XenForoUser[], not_verified_users: XenForoUser[] }[]
        exempted_users: string[][]
    }

    const [report, setReport] = useState<MultiAccountReport>();

    useEffect(() => {
        setLoading(true);
        getAuthenticated(`/api/account/mas/report`, auth).then(res => {
            if (res && res.data) {
                setReport(res.data);
            };
            setLoading(false);
        }, err => {
            setError(err);
            setLoading(false);
        });
    }, []);

    const convertUsers = (users: { primary_user: XenForoUser, secondary_users: XenForoUser[] }[]): TableEntry[] => {
        return users.map(user => {
            return {
                ...user.primary_user,
                secondary_users: user.secondary_users.map(secondaryUser => {
                    return {
                        ...secondaryUser,
                        secondary_users: []
                    }
                })
            }
        })
    }

    return (
        <>
            <Header>Instructions</Header>
            <TextContent>
                <p>{"<filler aconite instructions>"}</p>
            </TextContent>
            <br />
            <Table
                wrapLines
                expandableRows={{
                    getItemChildren: (item: TableEntry) => {
                        return item.secondary_users;
                    },
                    isItemExpandable: (item: TableEntry) => item.secondary_users && item.secondary_users.length > 0,
                    expandedItems: expandedItems,
                    onExpandableItemToggle: ({ detail }) => {
                        setExpandedItems(prev => {
                            const next = new Set(
                                (prev ?? []).map(item => item.username)
                            );
                            if (detail.expanded) {
                                next.add(detail.item.username);
                            } else {
                                next.delete(detail.item.username);
                            }
                            return Array.from(next).map(username => ({ username }));
                        });
                    }
                }}
                columnDefinitions={[
                    {
                        id: "user_id",
                        header: "User ID",
                        cell: e => e.user_id,
                    },
                    {
                        id: "username",
                        header: "Username",
                        cell: e => (<Link href={`/account-detail/${e.user_id}`}>{e.username}</Link>)
                    },
                    {
                        id: "user_state",
                        header: "User State",
                        cell: e => e.user_state
                    },
                    {
                        id: "is_banned",
                        header: "Is Banned",
                        cell: e => e.is_banned ? "Yes" : "No"
                    },
                    {
                        id: "verification_status",
                        header: "Verification Status",
                        cell: e => e.verification_status
                    },
                    {
                        id: "ip_addresses",
                        header: "IP Addresses",
                        cell: e => (
                            <>
                                {e.shared_ip_addresses.map((ip, index) => (
                                    <React.Fragment key={ip}>
                                        <Link href={`https://whatismyipaddress.com/ip/${ip}`} target="_blank">
                                            {ip}
                                        </Link>
                                        {index < e.shared_ip_addresses.length - 1 && ", "}
                                    </React.Fragment>
                                ))}
                            </>
                        )
                    }
                ]}
                items={convertUsers(report?.users || [])}
                loading={loading}
                loadingText="Loading resources"
                trackBy="username"
                header={
                    <Header>
                        Users
                    </Header>
                }
            />
            <br />
            <Table
                wrapLines
                columnDefinitions={[
                    {
                        id: "username",
                        header: "Banned Users",
                        cell: e => e.banned_users.map(user => user.username).join(", ")
                    },
                    {
                        id: "username",
                        header: "Unbanned Users",
                        cell: e => e.unbanned_users.map(user => user.username).join(", ")
                    }

                ]}
                items={report?.ban_evasion || []}
                loading={loading}
                loadingText="Loading resources"
                trackBy="username"
                header={
                    <Header>
                        Ban Evasion
                    </Header>
                }
            />
            <br />
            <Table
                wrapLines
                columnDefinitions={[
                    {
                        id: "username",
                        header: "Verified Users",
                        cell: e => e.verified_users.map(user => user.username).join(", ")
                    },
                    {
                        id: "username",
                        header: "Not Verified Users",
                        cell: e => e.not_verified_users.map(user => user.username).join(", ")
                    }

                ]}
                items={report?.verification_tracking || []}
                loading={loading}
                loadingText="Loading resources"
                trackBy="username"
                header={
                    <Header>
                        Verification Tracking
                    </Header>
                }
            />
            <br />
            <Header>Exempted Users</Header>
            <TextContent>
                <ul>
                    {report?.exempted_users[0].map(string => <li>{string}</li>)}
                </ul>
            </TextContent>
        </>
    )
}
