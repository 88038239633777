import { ColumnLayout, Header, Form, FormField, SpaceBetween, Spinner, Tabs, ButtonDropdown, Button, Textarea, Input, Pagination } from "@cloudscape-design/components";
import Table from "@cloudscape-design/components/table";
import { useEffect, useState } from "react";
import { useRoles } from "../Secured";
import getAuthenticated from "../request";
import { Link, NavLink, useParams } from "react-router-dom";
import { FieldBoolean, FieldValue, FieldValueElement } from "../FieldValue";
import { Character, CharactersFromJson } from "../interfaces/Character";
import { useErrorContext } from "../ErrorContext";
import AccountPeep from "../user-management/AccountPeep";
import menu from '../menu.json'
import logSearches from '../logSearches.json'
import { useOktaAuth } from '@okta/okta-react';
import OktaAuth from "@okta/okta-auth-js";


export default function CharacterViewer() {
    const [nameField, setNameField] = useState('')
    const [formName, setFormName] = useState<string | undefined>()
    const { setError } = useErrorContext()
    const { character } = useParams<{ character: string }>()

    const roles = useRoles()
    console.log(roles)

    const validRoles = menu.find(m => m.id === 'character-viewer')?.roles
    if (!roles?.some(role => validRoles?.includes(role))) {
        setError("You do not have permission to view this page")
        return null
    }

    // If we have a character name in the form, use that
    if (!!formName) return <CharacterView name={formName} />

    // If we have a character name in the URL, use that
    if (character) {
        // character is either a first name, or an OID number
        if (character.match(/^[0-9]+$/)) {
            return <CharacterView oid={character} />
        } else {
            return <CharacterView name={character} />
        }
    }

    // Otherwise, show the form
    return <form className="wide-container" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
        <Form
            actions={<Button variant="primary" onClick={() => {
                if (nameField.length > 0) {
                    setFormName(nameField)
                }
            }}>View Character</Button>}
        >
            <FormField label="Character First Name" description="Exact match only (case insensitive)">
                <Input
                    onChange={({ detail }) => setNameField(detail.value)}
                    value={nameField}
                    autoFocus
                    type="search"
                />
            </FormField>
        </Form>
    </form>
}

function CharacterView({ name, oid }: { name?: string, oid?: string }) {
    const [loading, setLoading] = useState(true)
    const [character, setCharacter] = useState<Character | null>(null)
    const { oktaAuth: auth } = useOktaAuth()
    const { setError } = useErrorContext()

    useEffect(() => {
        getAuthenticated(`/api/character`, auth, { params: { name, oid } }).then(res => {
            if (res) {
                setCharacter(CharactersFromJson([res.data])[0])
                setLoading(false)
            }
        }, err => {
            setLoading(false)
            setError(err)
        })
    }, [name])

    return <>
        {loading
            ? <Spinner size="large" />
            : character && <>
                <CharacterHeader character={character} />
                <CharacterViewTabs character={character} />
            </>
        }
    </>
}

function setTitle(character: Character) {
    document.title = `${character.character_full_name} - The Maw`
}

function CharacterHeader({ character }: { character: Character }) {
    const [loading, setLoading] = useState(false)
    const [unstuck, setUnstuck] = useState(false)
    const { oktaAuth: auth } = useOktaAuth()
    const { setError } = useErrorContext()

    return <Header variant="h2"
        actions={
            <Button
                disabled={loading || unstuck}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setLoading(true)

                    getAuthenticated(`/api/character/unstick/${character.creature_object}`, auth).then(res => {
                        setLoading(false)
                        setUnstuck(true)
                    }, err => {
                        setLoading(false)
                        setError(err)
                    })
                }} iconName={unstuck ? 'check' : undefined}
                iconSvg={loading ? <Spinner /> : undefined}
                iconAlign='right'>Unstick Character</Button>
        }
    >{character.character_full_name}</Header>

}

function CharacterViewTabs({ character }: { character: Character }) {
    const [activeTab, setActiveTab] = useState("overview")

    setTitle(character)

    return (
        <Tabs
            activeTabId={activeTab}
            onChange={({ detail }) => setActiveTab(detail.activeTabId)}
            tabs={[
                {
                    id: 'overview',
                    label: 'Overview',
                    content: <Overview character={character} />
                },
                {
                    id: 'logs',
                    label: 'Logs',
                    content: <Logs character={character} />
                }
            ]}
        />
    );
}

function Overview({ character }: { character: Character }) {
    const [loading, setLoading] = useState(true)
    const [accountId, setAccountId] = useState<string>('')
    const { oktaAuth: auth } = useOktaAuth()
    const setError = useErrorContext().setError

    useEffect(() => {
        getAuthenticated(`/api/account`, auth, { params: { character: character.character_first_name } }).then(res => {
            if (res) {
                setAccountId(res.data[0])
                setLoading(false)
            }
        }, err => {
            setError(err)
            setLoading(false)
        })
    }, [])

    return <>
        <SpaceBetween direction="vertical" size="s">
            <FieldValue field="Player Object ID" value={character.player_object} />
            <FieldValue field="Creature Object ID" value={character.creature_object} />
            <FieldValue field="Creation Time (UTC)" value={character.create_time?.toLocaleString() || ''} />
            <FieldValue field="Last Login Time (UTC)" value={character.last_login_time?.toLocaleString() || ''} />
            {character.deleted_date && <FieldValue field="Deleted Time (UTC)" value={character.deleted_date.toLocaleString()} />}
            <FieldValueElement field="Account" value={loading ? <Spinner /> : <AccountPeep userId={accountId} />} />
        </SpaceBetween>
    </>
}

function Logs({ character }: { character: Character }) {
    const [logType, setLogType] = useState(logSearches[0].name)
    const [days, setDays] = useState("2")
    const [fetchClicked, setFetchClicked] = useState(false)

    return <>
        <form className="wide-container" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
            <Form
                actions={<Button variant="primary" onClick={() => {
                    setFetchClicked(true)
                }}>Fetch</Button>}
            >
                <SpaceBetween direction="horizontal" size="m">
                    <FormField>
                        <ButtonDropdown
                            items={logSearches.map(log => {
                                return {
                                    id: log.name,
                                    text: log.name,
                                    disabled: false
                                }
                            })}
                            onItemClick={({ detail }) => {
                                setLogType(detail.id)
                            }}
                        >
                            {logType}
                        </ButtonDropdown>
                    </FormField>
                    <FormField>
                        <ButtonDropdown
                            items={[2, 7, 14, 30, 90].map((days) => {
                                return {
                                    id: days + "",
                                    text: `${days} Days`,
                                    disabled: false
                                }
                            })}
                            onItemClick={({ detail }) => {
                                setDays(detail.id)
                            }}
                        >
                            Last {days} Days
                        </ButtonDropdown>
                    </FormField>
                </SpaceBetween>
            </Form>
        </form>
        <LogTable character={character} logType={logType} days={days} go={fetchClicked} />
    </>
}

function fetchLogs(character: Character, logType: string, days: string, auth: OktaAuth, setLogs: Function, setLoading: Function, setError: Function, page: number = 1) {
    getAuthenticated(`/api/character/logs/${character.creature_object}/${logType}/${days}/${page}`, auth).then(res => {
        if (res) {
            setLogs(res.data.hits.hits);
            setLoading(false);
        }
    }, err => {
        setError(err);
        setLoading(false);
    });
}

function LogTable({ character, logType, days, go }: { character: Character, logType: string, days: string, go: boolean }) {
    const [loading, setLoading] = useState(false)
    const [logs, setLogs] = useState<any>(null)
    const [page, setPage] = useState(1)
    const { oktaAuth: auth } = useOktaAuth()
    const { setError } = useErrorContext()
    const [search, setSearch] = useState('')

    useEffect(() => {
        if (go && character && logType && days) {
            setLoading(true);
            fetchLogs(character, logType, days, auth, setLogs, setLoading, setError, page);
        }
    }, [go, character, logType, days, page])

    // if(loading) return <Spinner size="large" />
    if (!go) return null

    const rows: LogRow[] = logs ? logs.map((log: any) => {
        return {
            timestamp: new Date(log.fields.logTimestamp[0]),
            message: log.fields.message[0]
        }
    }) : []

    const pagination = <Pagination
        currentPageIndex={page}
        pagesCount={5}
        openEnd
        ariaLabels={{
            nextPageLabel: "Next page",
            previousPageLabel: "Previous page",
            pageLabel: pageNumber =>
                `Page ${pageNumber} of all pages`
        }}
        onChange={({ detail }) => {
            setPage(detail.currentPageIndex)
        }}
    />
    return <>
        <SpaceBetween direction="vertical" size="m">
            <Header>Results</Header>
            <Input value={search} placeholder="Search within results..." type="search" onChange={({ detail }) => setSearch(detail.value)} />
            <Table
                columnDefinitions={logColumns}
                items={rows.filter(row => row.message.toLowerCase().includes(search.toLowerCase()) || row.timestamp.toLocaleString().includes(search))}
                pagination={pagination}
                loading={loading}
            />
        </SpaceBetween>
    </>
}

const logColumns = [
    { id: 'timestamp', header: 'Timestamp', cell: (row: LogRow) => <div>{row.timestamp?.toLocaleString() || ''}</div> },
    { id: 'message', header: 'Message', cell: (row: LogRow) => <div>{row.message}</div> },
]

interface LogRow {
    timestamp: Date
    message: string
}