import OktaAuth from "@okta/okta-auth-js";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios"

axios.interceptors.request.use(config => {
    if (!config?.headers ? ['X-Separatist'] : false) {
        throw new Error('Must use internal request library for requests')
    }
    (config?.headers || {})['X-Separatist'] = undefined

    return config
})

export default function getAuthenticated(url: string, auth: OktaAuth, config?: AxiosRequestConfig) {
    return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
        const token = auth.getAccessToken()
        config = buildConfig(auth, config)
        axios.get(url, config).then(resolve, reject)
    })
}

export function putAuthenticated(url: string, body: any, auth: OktaAuth, config?: AxiosRequestConfig) {
    return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
        config = buildConfig(auth, config)
        axios.put(url, body, config).then(resolve, reject)
    })
}

export function postAuthenticated(url: string, body: any, auth: OktaAuth, config?: AxiosRequestConfig) {
    return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
        config = buildConfig(auth, config)
        axios.post(url, body, config).then(resolve, reject)
    })
}

export function deleteAuthenticated(url: string, auth: OktaAuth, config?: AxiosRequestConfig) {
    return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
        config = buildConfig(auth, config)
        axios.delete(url, config).then(resolve, reject)
    })
}

const buildConfig = (auth: OktaAuth, config?: AxiosRequestConfig) => {
    const token = auth.getAccessToken()
    if (token) {
        config = config || {}
        config.headers = config.headers || {}
        config.headers.Authorization = `Bearer ${token}`
        config.headers['X-Separatist'] = true
    }
    return config
}