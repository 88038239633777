import { Box, Header, Link, Pagination, SpaceBetween, TextFilter } from "@cloudscape-design/components";
import Table, { TableProps } from "@cloudscape-design/components/table";
import { useEffect, useState } from "react";
import getAuthenticated from "../request";
import { useOktaAuth } from '@okta/okta-react';

import PieChart from "@cloudscape-design/components/pie-chart";
import Button from "@cloudscape-design/components/button";
import { JediEnumValue, JediStatEnumValues, JediStats, JediStatsFromJson } from "../interfaces/Stats";
import { useErrorContext } from "../ErrorContext";
import { NavLink } from "react-router-dom";

export default function Metrics() {
    const [data, setData] = useState<JediStats[]>([])
    const [loading, setLoading] = useState(true)
    const { oktaAuth:auth } = useOktaAuth()
    const setError = useErrorContext().setError
        
    useEffect(() => {
        getAuthenticated('/api/jedi/stats/', auth).then((response) => {
            setLoading(false)
            setData(JediStatsFromJson(response.data));
        }, err => { 
            setLoading(false)
            setError(err.message) 
        });
    }, [])

    if(loading) {
        return <PieChart statusType="loading" loadingText={`Loading Stats`} data={[]}/>
    } else {
        return <Chart data={data} />
    }

}

function Chart({data}: {data: JediStats[]}) {
    const [highlighted, setHighlighted] = useState<JediEnumValue | null>(null)

    // turn the data into a map of totals for each enum value
    const totals = data.reduce((acc, datum) => {
        const value = datum.value
        if(acc[value]) {
            acc[value] += 1
        } else {
            acc[value] = 1
        }
        return acc
    }, {} as Record<JediEnumValue, number>)

    const segments = (Object.keys(totals) as Array<keyof typeof totals>).map(key => { return {
        title: key,
        value: totals[key]
    }})

    return <SpaceBetween
        direction="horizontal"
        size="m"
        >
        <PieChart
            data={segments}
            detailPopoverContent={(datum, sum) => [
                { key: "Count", value: datum.value },
                {
                    key: "Percentage",
                    value: `${((datum.value / sum) * 100).toFixed(
                        0
                    )}%`
                },
                { key: "Game enum", value: (Object.keys(JediStatEnumValues) as unknown as Array<keyof typeof JediStatEnumValues>).find(key => JediStatEnumValues[key] === datum.title)?.toString() || 'Unknown' }
            ]}
            segmentDescription={(datum, sum) =>
                `${datum.value} players, ${(
                    (datum.value / sum) *
                    100
                ).toFixed(0)}%`
            }
            i18nStrings={{
                detailsValue: "Value",
                detailsPercentage: "Percentage",
                filterLabel: "Filter displayed data",
                filterPlaceholder: "Filter data",
                filterSelectedAriaLabel: "selected",
                detailPopoverDismissAriaLabel: "Dismiss",
                legendAriaLabel: "Legend",
                chartAriaRoleDescription: "pie chart",
                segmentAriaRoleDescription: "segment"
            }}
            ariaDescription="Pie chart showing how many Jedi are currently in which state."
            ariaLabel="Pie chart"
            errorText="Error loading data."
            loadingText="Loading chart"
            recoveryText="Retry"
            variant="donut"
            hideFilter
            innerMetricDescription="Total Players"
            innerMetricValue={data.length + ''}
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No data available</b>
                    <Box variant="p" color="inherit">
                        There is no data available
                    </Box>
                </Box>
            }
            noMatch={
                <Box textAlign="center" color="inherit">
                    <b>No matching data</b>
                    <Box variant="p" color="inherit">
                        There is no matching data to display
                    </Box>
                    <Button>Clear filter</Button>
                </Box>
            }
            onHighlightChange={(item) => setHighlighted(item?.detail.highlightedSegment?.title || null)}
            // highlightedSegment={highlighted ? segments.find(s => s.title === highlighted) : null}
        />
        <FilteredTable data={data.filter(jedi => jedi.value === highlighted)} />
    </SpaceBetween>
}

function FilteredTable({data}: {data: JediStats[]}) {
    const [page, setPage] = useState(1)
    const pageSize = 10
    useEffect(() => {
        setPage(1)
    }, [data])

    if(data.length === 0) return null

    const columnDefinitions: any = [
        {
            id: 'OID',
            header: 'OID',
            description: 'The OID of the Jedi',
            sortingField: 'OID',
            sortingComparator: (a: JediStats, b: JediStats) => a.OID.localeCompare(b.OID),
            cell: (item: JediStats) => <NavLink to={`/character-viewer/${item.OID}`}><Link>{item.OID}</Link></NavLink>
        }
    ]

    const paginatedRows = data.length > pageSize * page 
        ? data.slice((page - 1) * pageSize, page * pageSize) 
        : data.slice(Math.max(0, data.length - pageSize), data.length)

    return <Table
        items={paginatedRows}
        columnDefinitions={columnDefinitions}
        pagination={
            <Pagination
                currentPageIndex={page}
                pagesCount={Math.ceil(data.length / pageSize)}
                onChange={({detail}) => {
                    setPage(detail.currentPageIndex)
                }}
            />
        }
    />
}