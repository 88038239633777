import { Box, Header, Pagination, TextFilter } from "@cloudscape-design/components";
import Table from "@cloudscape-design/components/table";
import { useEffect, useState } from "react";
import getAuthenticated from "../request";
import { useOktaAuth } from '@okta/okta-react';

const PAGE_SIZE = 100

export default function DataTable({id, type}: {id: string, type: string}) {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const { oktaAuth:auth } = useOktaAuth()
        
    useEffect(() => {
        getAuthenticated('/api/table/' + id, auth).then((response) => {
            setData(response.data);
        }, err => { 
            setError(err.message) 
        });
    }, [id])

    if(error) {
        return <div className="error">{error}</div>
    } else if(!data) {
        return <Table loading={true} loadingText={`Loading ${type}`} items={[]} columnDefinitions={[]} />
    } else {
        return <ResolvedDataTable data={data} type={type} />
    }

}

function ResolvedDataTable({ data, type }: { data: string, type: string }) {

    const lines = data.split('\n');
    const headers = lines[0].toLowerCase().replaceAll(' ', '_').split('\t');
    const types = lines[1].split('\t');
    const rows = [];

    // map each cell in a row of data to the corresponding column key then insert
    for (let rowIndex = 2; rowIndex < lines.length; rowIndex++) {
        let row: any = { id: rowIndex };
        const line = lines[rowIndex].split('\t');
        for (let c = 0; c < line.length; c++) {
            if (line[c]) {
                row[headers[c]] = line[c];
            }
            // for empty cells, we'll populate with the column's default value if we have one
            // (skip columns with required or include constraints since those aren't default values)
            else {
                if (types[c].includes('[') &&
                    !types[c].toLowerCase().includes('required') &&
                    !types[c].toLowerCase().includes('unique')
                ) {
                    row[headers[c]] = types[c].split('[').pop()?.split(']')[0];
                }
                else // if we didn't find a default value specified, fall back to logic in DataTableColumnType.cpp
                {
                    switch (types[c].charAt(0)) {
                        case "i":
                        case "f":
                        case "b":
                            row[headers[c]] = "0";
                            break;
                        default:
                            row[headers[c]] = "";
                            break;
                    }
                }
            }
        }
        rows.push(row);
    }

    const columnDefinitions: any = []

    // populate the header data for each column
    for (let i = 0; i < headers.length; i++) {
        columnDefinitions.push({
            id: i,
            header: headers[i],
            description: types[i],
            // sortingField: headers[i],
            sortingComparator: (a: any, b: any) => {
                if(!a[headers[i]]) return -1
                if(!b[headers[i]]) return 1
                return a[headers[i]].localeCompare(b[headers[i]]);
            },
            cell: (item: any) => {
                return item[headers[i]]
            }
        })
    }

    // pass this data to the table component so that it doesn't need to recalculate on rerenders
    return <PaginatedDataTable rows={rows} columnDefinitions={columnDefinitions} type={type}/>
}

function PaginatedDataTable({rows, columnDefinitions, type}: {rows: any[], columnDefinitions: any[], type: string}) {
    
    const [filterString, setFilterString] = useState("")
    const [sortedColumn, setSortedColumn] = useState(columnDefinitions[0])
    const [sortDescending, setSortDescending] = useState(false)
    const [page, setPage] = useState(1)

    rows = rows.sort((a, b) => {
        let result = sortedColumn.sortingComparator(a, b)
        if(sortDescending) result *= -1
        return result
    })

    // filter the rows based on the filter string
    const filteredRows = filterString.length > 0 ? rows.filter(row => {

        // tokenize the filter string, and filter out any rows without values that don't match all of the tokens
        return filterString.split(' ').every(filterToken => {
            if(!filterToken) return true
            filterToken = filterToken.toLowerCase()

            // if this is a column value check, only check the column specified
            if(filterToken.includes('=')) {
                const [key, value] = filterToken.split('=')
                return row[key] && row[key].toLowerCase().includes(value)
            } 
    
            // filter out rows that don't contain the filter string in any of their cells
            return Object.values(row).some((value: any) => value?.toString().toLowerCase().includes(filterToken))
        })
        
    }) : rows

    // slice down results to the current page
    const paginatedRows = filteredRows.length > PAGE_SIZE * page 
        ? filteredRows.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE) 
        : filteredRows.slice(Math.max(0, filteredRows.length - PAGE_SIZE), filteredRows.length)

    const pagination = <Pagination
        currentPageIndex={page}
        pagesCount={Math.ceil(filteredRows.length / PAGE_SIZE)}
        ariaLabels={{
            nextPageLabel: "Next page",
            previousPageLabel: "Previous page",
            pageLabel: pageNumber =>
                `Page ${pageNumber} of all pages`
        }}
        onChange={({detail}) => {
            setPage(detail.currentPageIndex)
        }}
    />

    const filter = <TextFilter
        filteringText={filterString}
        filteringPlaceholder={`Search ${type}. To filter a specific column, use "column=value"`}
        onChange={({detail}) => setFilterString(detail.filteringText)}
    />

    return (
        <Table
            columnDefinitions={columnDefinitions}
            items={paginatedRows}
            loadingText="Loading resources"
            trackBy="id"
            stickyHeader
            onSortingChange={(event) => {
                setSortedColumn(event.detail.sortingColumn)
                setSortDescending(event.detail.isDescending!)
            }}
            sortingColumn={sortedColumn}
            sortingDescending={sortDescending}
            empty={
                <Box textAlign="center" color="inherit">
                    <b>None Found</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No data to display.
                    </Box>
                </Box>
            }
            filter={filter}
            header={
                <Header>
                    {type}
                </Header>
            }
            pagination={pagination}
        />
    );
}