
export interface Grant{
    grant_reason: string;
    amount: number;
    is_claimed: boolean;
    grant_date: Date;
}
export const GrantsFromJson = (json: any): Grant[] => {
    console.log(json)
    return json.grants?.map((grant: any) => {
            return {
                grant_reason: grant.grant_reason,
                amount: grant.amount,
                is_claimed: grant.is_claimed,
                grant_date: parseDate(grant.grant_date)
            }   
    })
}

const parseDate = (date: any) => {
    if(date && date !== "1970-01-01T00:00:00Z") {
        return new Date(date)
    }
    return undefined
}