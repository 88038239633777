import React, { useState } from 'react';
import DataTable from './DataTable';
import { Tabs } from '@cloudscape-design/components';
import JediStats from './JediStats';
import VeteranRewards from './VeteranRewards';
import IdentityVerifications from './IdentityVerifications';

function Metrics() {

    const [activeTab, setActiveTab] = useState('Jedi')

    return (
        <Tabs
            activeTabId={activeTab}
            onChange={({ detail }) => setActiveTab(detail.activeTabId)}
            tabs={[{
                id: 'Jedi',
                label: 'Jedi',
                content: <JediStats />
            }, {
                id: 'Veteran',
                label: 'Veteran Rewards',
                content: <VeteranRewards />
            },
            {
                id: 'IDVerifications',
                label: 'Identity Verifications',
                content: <IdentityVerifications />
            }]}
        />
    );
}

export default Metrics;