import React, { useState } from "react";
import { useRoles } from "./Secured";
import menu from './menu.json'

import { useNavigate } from "react-router-dom";
import { Box, ColumnLayout, Header, Input, InputProps } from "@cloudscape-design/components";
import { faCircleUser, faCoins, faTableColumns, faTableList, faPerson, faPieChart, faCode, faBagShopping, faPlus, faQuestionCircle, faPen, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const ICONS: any = {
    'datatable-viewer': faTableList,
    'string-search': faTableColumns,
    'account-finder': faCircleUser,
    'fortitude-grant': faCoins,
    'fortitude-pending': faCoins,
    'character-viewer': faPerson,
    'metrics': faPieChart,
    'admin-commands': faCode,
    'object-containment': faBagShopping,
    'object-lookup': faQuestionCircle,
    'baselining-admin': faPlus,
    'baselining-actor': faCode,
    'patch-notes-tool': faPen,
    'mas-report': faTable
}

export default function Menu() {

    const roles = useRoles() || []

    const menuItems = menu.filter(item => item.roles?.length === 0 || roles.some(role => item.roles?.includes(role))).map(item => { return { ...item, name: item.text } }) as any[]

    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const [invalidSearch, setInvalidSearch] = useState(false);

    const handleSearch = (input: string) => {
        const match = input.match(/^(.*):(.*)$/);
        if (match) {
            const [, type, value] = match;
            navigate(`/account-finder?type=${type}&q=${value}`);
        } else {
            setInvalidSearch(true);
        }
    };

    return <div className="menu">
        <div className="wide-container">
            <Section name="User Management" menuItems={menuItems.filter(item => item.category === 'User Management')} />
            {roles.includes('account_view') ?
                <>
                    <Input value={search}
                        onChange={({ detail }) => { setSearch(detail.value); setInvalidSearch(false) }}
                        placeholder="Quick-search account finder..."
                        invalid={invalidSearch}
                        onKeyDown={(e: CustomEvent<InputProps.KeyDetail>) => {
                            if (e.detail.keyCode === 13) {
                                console.log(e.detail)
                                handleSearch(search);
                            }
                        }} />
                    <br />
                </> : null}

            <Section name="Game Data" menuItems={menuItems.filter(item => item.category === 'Game Data')} />
            <Section name="Support" menuItems={menuItems.filter(item => item.category === 'Support')} />
        </div>
    </div>
}

function Section({ name, menuItems }: { name: string, menuItems: any[] }) {
    if (menuItems.length === 0) return null

    return <Box margin={{ bottom: 'l' }}>
        <Header variant="h2" >{name}</Header>
        <br />
        <ColumnLayout columns={2} >
            {
                menuItems.map(item => <Card key={item.id} item={item} />)
            }
        </ColumnLayout>
    </Box>
}

function Card({ item }: { item: any }) {
    const navigate = useNavigate()

    return <div key={item.id} className="maw-card" onClick={() => navigate(`/${item.id}`)}>
        <Box fontSize="heading-l" fontWeight="bold" variant="p">
            <i className="fas fa-fw" style={{ marginRight: '0.5rem' }}><FontAwesomeIcon icon={ICONS[item.id] as IconProp} /></i>
            {item.text}
        </Box>
    </div>
}
