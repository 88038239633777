import React, { useState } from "react";
import Table from "@cloudscape-design/components/table";
import { useEffect } from "react";
import { useErrorContext } from "../ErrorContext";
import getAuthenticated from "../request";
import { useOktaAuth } from '@okta/okta-react';
import { Rewards, veteranRewardFromJSON } from "../interfaces/Rewards";

function VeteranRewards() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({ rewards: [], daysTokens: [], hoursTokens: [] } as Rewards);
    const { oktaAuth: auth } = useOktaAuth()
    const setError = useErrorContext().setError

    useEffect(() => {
        getAuthenticated('/api/veteranrewards/metrics/', auth).then((response) => {
            setLoading(false)
            setData(veteranRewardFromJSON(response.data));
        }, err => {
            setLoading(false)
            setError(err.message)
        });
    }, [])

    return (<>
        <Table
            loading={loading}
            loadingText={`Loading Rewards`}
            items={data.rewards}
            columnDefinitions={[{ id: 'reward', header: 'Reward', cell: (row) => row.name }, { id: 'quantity', header: 'Quantity', cell: (row) => row.quantity }]}
        />
        <br />
        <Table
            loading={loading}
            loadingText={`Loading Days Tokens`}
            items={data.daysTokens}
            columnDefinitions={[{ id: 'days', header: 'Days Token', cell: (row) => row.name }, { id: 'quantity', header: 'Quantity', cell: (row) => row.quantity }]}
        />
        <br />
        <Table
            loading={loading}
            loadingText={`Loading Hours Tokens`}
            items={data.hoursTokens}
            columnDefinitions={[{ id: 'hours', header: 'Hours Token', cell: (row) => row.name }, { id: 'quantity', header: 'Quantity', cell: (row) => row.quantity }]}
        />
    </>
    );
}

export default VeteranRewards;