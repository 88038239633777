import TopNavigation, { TopNavigationProps } from "@cloudscape-design/components/top-navigation";
import { applyMode, Mode } from '@cloudscape-design/global-styles';
import {ReactComponent as Moon} from './moon.svg'
import { useEffect, useState } from "react";
import { useAuth, useRoles } from "./Secured";
import menu from './menu.json'

import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

export default () => {
  const [dark, setDark] = useState(localStorage.getItem('mode') === 'dark' ? true : false);
  const okta = useOktaAuth()

  const { preferred_username } = useAuth()?.idToken?.claims || {}

  const roles = useRoles() || []
  const navigate = useNavigate()

  const menuGroups: TopNavigationProps.Utility[] = [{
    type: "menu-dropdown" as "menu-dropdown",
    text: "User Management",
    items: menu.filter(item => item.category === 'User Management' && (item.roles?.length === 0 || roles.some(role => item.roles?.includes(role)))),
    onItemClick: (item: any) => {
      navigate(`/${item.detail.id}`)
    }
  },
  {
      type: "menu-dropdown" as "menu-dropdown",
      text: "Game Data",
      items: menu.filter(item => item.category === 'Game Data' && (item.roles?.length === 0 || roles.some(role => item.roles?.includes(role)))),
      onItemClick: (item: any) => {
        navigate(`/${item.detail.id}`)
      }
  },
  {
    type: "menu-dropdown" as "menu-dropdown",
    text: "Support",
    items: menu.filter(item => item.category === 'Support' && (item.roles?.length === 0 || roles.some(role => item.roles?.includes(role)))),
    onItemClick: (item: any) => {
      navigate(`/${item.detail.id}`)
    }
  }].filter(group => group.items.length > 0)

  return (
    <TopNavigation
      identity={{
        href: "/",
        title: "The Maw",
        logo: {
          src:
            "/logo192.png",
          alt: "Maw"
        }
      }}
      utilities={[
        ...menuGroups, 
        {
          type: "button",
          iconSvg: <Moon/>,
          iconAlt: "Dark Mode",
          ariaLabel: "Toggle Dark Mode",
          onClick: () => {
            applyMode(dark? Mode.Light : Mode.Dark)
            setDark(!dark)
            localStorage.setItem('mode', dark? 'light' : 'dark')
          }
        },
        {
          type: "menu-dropdown",
          text: preferred_username || 'User',
          iconName: "user-profile",
          iconAlt: "User Profile Menu",
          title: preferred_username,
          items: [
            {
              id: "manage",
              text: "Manage Account",
              href: "https://omitv.okta.com/",
              external: true
            },
            {
              id: "reset-login",
              text: "Sign out"
            }
          ], 
          
          onItemClick: (item: any) => {
            if (item.detail.id === 'reset-login') {
              localStorage.removeItem('token')
              okta.oktaAuth.signOut()
            }
          }
        }
      ]}
      i18nStrings={{
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
        overflowMenuBackIconAriaLabel: "Back",
        overflowMenuDismissIconAriaLabel: "Close menu"
      }}
    />
  );
}