import { Header, Table } from "@cloudscape-design/components";
import { Grant } from "../interfaces/Fortitude";

export default function FortitudeGrantsTable({grants, loading}: {grants: Grant[], loading: boolean}) {
    return <Table
        columnDefinitions={[
            {id: 'grant_date', header: 'Date', cell: (row: Grant) => <div>{row.grant_date.toLocaleDateString()}</div>},
            {id: 'amount', header: 'Amount', cell: (row: Grant) => <div>{row.amount}</div>},
            {id: 'grant_reason', header: 'Reason', cell: (row: Grant) => <div>{row.grant_reason}</div>},
            {id: 'is_claimed', header: 'Claimed', cell: (row: Grant) => <div>{row.is_claimed ? 'Yes' : 'No'}</div>},
        ]}
        items={(grants || []).sort((a, b) => b.grant_date.getTime() - a.grant_date.getTime())}
        loadingText="Loading Grants..."
        empty="No Previous Grants Found"
        loading={loading}
    />
}