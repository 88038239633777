export interface Rewards {
    rewards: item[],
    daysTokens: item[],
    hoursTokens: item[]
}

interface item {
    name: string,
    quantity: number
}

export function veteranRewardFromJSON(json: any): Rewards {
    const daysTokensKeys = new Set(Object.keys(json).filter(key => key.startsWith('Days Token')));
    const hoursTokensKeys = new Set(Object.keys(json).filter(key => key.startsWith('Hours Token')));

    return {
        rewards: Object.keys(json).filter(key => !daysTokensKeys.has(key) && !hoursTokensKeys.has(key)).map(key => {
            return {
                name: key,
                quantity: json[key]
            }
        }),
        daysTokens: Array.from(daysTokensKeys).map(key => {
            return {
                name: key,
                quantity: json[key]
            }
        }),
        hoursTokens: Array.from(hoursTokensKeys).map(key => {
            return {
                name: key,
                quantity: json[key]
            }
        })
    }
}