import { ColumnLayout, Header, Link, SpaceBetween, Spinner, Tabs, Button, ButtonDropdown, Modal, Box } from "@cloudscape-design/components";
import Table from "@cloudscape-design/components/table";
import { useEffect, useState } from "react";
import { useRoles } from "../Secured";
import getAuthenticated from "../request";
import { NavLink, useParams } from "react-router-dom";
import { FieldBoolean, FieldValue } from "../FieldValue";
import Account, { AccountFromJson, Change, ChangesFromJson, IPLog, IPsFromJson, SupportTicket, SupportTicketsFromJson, Warning, WarningData, WarningDataFromJson } from "../interfaces/Account";
import { Character, CharactersFromJson } from "../interfaces/Character";
import { useErrorContext } from "../ErrorContext";
import { Grant, GrantsFromJson } from "../interfaces/Fortitude";
import FortitudeGrantsTable from "./FortitudeGrantsTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPen, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useOktaAuth } from '@okta/okta-react';
import { postAuthenticated } from "../request";

const PII_ROLE = 'account_view_pii'

export default function AccountView({ userId, setUser }: { userId?: string, setUser?: Function }) {
    const [loading, setLoading] = useState(true)
    const [account, setAccount] = useState<Account | null>(null)
    const { oktaAuth: auth } = useOktaAuth()
    const { userId: urlUserId } = useParams<{ userId: string }>()
    const id = userId || urlUserId
    const { setError } = useErrorContext()

    useEffect(() => {
        getAuthenticated(`/api/account/${id}`, auth).then(res => {
            if (res) {
                const account = AccountFromJson(res.data)
                setAccount(account)
                setLoading(false)
                setUser && setUser(account)
            }
        }, err => {
            setLoading(false)
            setError(err)
        })
    }, [id])

    return <>
        {loading
            ? <Spinner />
            : account && <>
                <AccountHeader account={account} userId={id!} setAccount={setAccount} />
                {urlUserId  // If we have a URL Id, we're full screen, so show tabs
                    ? <AccountViewTabs account={account} userId={id!} />
                    : <Overview account={account} />}
            </>
        }
    </>
}

function setTitle(account: Account) {
    document.title = `${account.username} - The Maw`
}

function AccountHeader({ account, userId, setAccount }: { account: Account, userId: string, setAccount: Function }) {
    const [loading, setLoading] = useState(false)
    const { setError } = useErrorContext()
    const { oktaAuth: auth } = useOktaAuth()
    const [confirmationVisible, setConfirmationVisible] = useState(false)

    const changeStatus = (status: string) => {
        setLoading(true)
        postAuthenticated(`/api/account/verify/`, {
            id: userId,
            email: account.email,
            status: status
        }, auth).then(res => {
            account.identity_verification_status = status
            setAccount({ ...account, identity_verification_status: status });
            setLoading(false)
        }, err => {
            setLoading(false)
            setError(err)
        })
    }


    return <Header variant="h2"
        actions={
            <>
                <ButtonDropdown
                    items={[
                        { id: 'required', text: 'Flag as Required', iconName: 'security', disabled: account.identity_verification_status === 'required' },
                        { id: 'verified', text: 'Mark Approved', iconName: 'check', disabled: account.identity_verification_status === 'verified' },
                        // {id: 'declined', text: 'User Declined', iconName: 'thumbs-down', disabled: account.identity_verification_status === 'verified'},
                    ]}
                    onItemClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        let newStatus = e.detail.id

                        if (newStatus == 'required' && account.identity_verification_status === 'verified') {
                            setConfirmationVisible(true)
                        } else {
                            changeStatus(newStatus)
                        }
                    }}
                    loading={loading}>
                    Identity Verification
                </ButtonDropdown>
                <Modal
                    onDismiss={() => setConfirmationVisible(false)}
                    visible={confirmationVisible}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link"
                                    onClick={() => { setConfirmationVisible(false) }}>Cancel</Button>
                                <Button variant="primary"
                                    onClick={() => {
                                        changeStatus('required')
                                        setConfirmationVisible(false)
                                    }}
                                >Yes change status again</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="Confirm change to verification status"
                >
                    This user is currently verified, are you sure you wish to require verification again?
                </Modal>
            </>
        }
        info={
            <Link href={`https://swgr.org/admin.php?users/${userId}/edit`}
                ariaLabel="Edit user"
                target="_blank">
                <FontAwesomeIcon title="Edit Account" icon={faUserPen} />
            </Link>}
    >{account.username}</Header>

}

function AccountViewTabs({ account, userId }: { account: Account, userId: string }) {

    const [activeTab, setActiveTab] = useState("overview")
    const roles = useRoles()

    setTitle(account)

    return (
        <Tabs
            activeTabId={activeTab}
            onChange={({ detail }) => setActiveTab(detail.activeTabId)}
            tabs={[
                {
                    id: 'overview',
                    label: 'Overview',
                    content: <Overview account={account} />
                },
                {
                    id: 'characters',
                    label: 'Characters',
                    content: <Characters userId={userId} />
                },
                {
                    id: 'changes',
                    label: 'Changes',
                    content: <Changes userId={userId} />
                },
                roles?.includes(PII_ROLE) ? {
                    id: 'ips',
                    label: 'IP Addresses',
                    content: <IPs userId={userId} />
                } : {
                    id: 'ips',
                    label: 'IP Addresses (Unavailable)',
                    content: <div>You don't have permission to view this page</div>
                },
                {
                    id: 'warnings',
                    label: 'Warnings/Infractions',
                    content: <Warnings userId={userId} />
                },
                {
                    id: 'tickets',
                    label: 'Support Tickets',
                    content: <SupportTickets userId={userId} />
                },
                {
                    id: 'fortitude',
                    label: 'Fortitude Tokens',
                    content: <FortitudeGrants username={account.username} />
                }
            ]}
        />
    );
}

function Overview({ account }: { account: Account }) {
    const roles = useRoles()

    return <>
        <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween direction="vertical" size="s">
                <FieldValue field="Email" value={roles?.includes(PII_ROLE) ? account.email : '[Insufficient permission]'} />
                <FieldValue field="Discord" value={`${account.discord} (${account.discord_key})`} />
                <FieldValue field="Join Date" value={account.register_date?.toLocaleString() || ''} />
                <FieldValue field="Last Activity" value={account.last_activity?.toLocaleString() || ''} />
                <FieldValue field="GUIDs" value={roles?.includes(PII_ROLE) ? account.guids?.join(', ') : '[Insufficient permission]'} />
                <FieldValue field="Station ID (Real)" value={account.suid_real} />
                <FieldValue field="Station ID (Comp)" value={account.suid_comp} />
                <FieldBoolean field="Staff Flag" value={account.is_staff} status={account.is_staff ? "Staff" : "User"} />
                <FieldValue field="God Level - Live Server" value={account.live_god_level + ""} />
                <FieldValue field="God Level - Test Server" value={account.test_god_level + ""} />
            </SpaceBetween>
            <SpaceBetween direction="vertical" size="s">
                <FieldValue field="User State" value={account.user_state} />
                <FieldBoolean field="Two-Step Verification Status" value={account.tfa} status={account.tfa ? "Enabled" : "Disabled"} />
                <FieldBoolean field="Communication Opt In/Out" value={account.receive_admin_email} status={account.receive_admin_email ? "Opted In" : "Opted Out"} />
                <FieldValue field="Security Lock" value={account.security_lock} />
            </SpaceBetween>
            <SpaceBetween direction="vertical" size="s">
                <FieldBoolean field="Banned" variant="red" value={account.is_banned} status={account.is_banned ? "Banned" : "Not Banned"} />
                {account.is_banned && <FieldValue field="Ban Date" value={account.ban_date?.toLocaleString() || ''} />}
                {account.is_banned && <FieldValue field="Ban End Date" value={account.end_date?.toLocaleString() || ''} />}
                {account.user_reason && <FieldValue field="Ban Reason" value={account.user_reason} />}
                <SpaceBetween direction="horizontal" size="s">
                    <FieldValue field="Identity Verification Status" value={account.identity_verification_status} />
                    <FontAwesomeIcon title="Multiple accounts from the same IP address require verification." icon={faInfoCircle} style={{ color: "white" }} />
                </SpaceBetween>
                <SpaceBetween direction="horizontal" size="s">
                    <FieldBoolean field="Identity Confirmation Stored" value={!!account.identity_verification_hash} status={!!account.identity_verification_hash ? "Yes" : "No"} />
                    <FontAwesomeIcon title="Indicates if the account actually completed verification through Stripe as opposed to just being manually verified" icon={faInfoCircle} style={{ color: "white" }} />
                </SpaceBetween>
                <FieldValue field="Identity Verification Attempts" value={account.identity_verification_attempts + ""} />
            </SpaceBetween>
        </ColumnLayout>
    </>
}

const characterColumns = [
    { id: 'character_full_name', header: 'Full Name', cell: (row: Character) => <NavLink to={`/character-viewer/${row.character_first_name}`}><Link>{row.character_full_name}</Link></NavLink> },
    { id: 'last_login_time', header: 'Last Login Time (UTC)', cell: (row: Character) => <div>{row.last_login_time?.toLocaleString() || ''}</div> },
    { id: 'create_time', header: 'Create Time (UTC)', cell: (row: Character) => <div>{row.create_time?.toLocaleString() || ''}</div> },
    { id: 'creature_object', header: 'Creature Object', cell: (row: Character) => <div>{row.creature_object}</div> },
    { id: 'player_object', header: 'Player Object', cell: (row: Character) => <div>{row.player_object}</div> },
]

function Characters({ userId }: { userId: string }) {
    const [loading, setLoading] = useState(true)
    const [characters, setCharacters] = useState<Character[]>([])
    const { oktaAuth: auth } = useOktaAuth()
    const { setError } = useErrorContext()

    useEffect(() => {
        getAuthenticated(`/api/account/${userId}/characters`, auth).then(res => {
            if (res) {
                setCharacters(CharactersFromJson(res.data.characters))
                setLoading(false)
            }
        }, err => {
            setError(err)
            setLoading(false)
        })
    }, [])

    const [active, deleted] = characters.reduce((acc, cur) => {
        if (!!cur.deleted_date) {
            acc[1].push(cur)
        } else {
            acc[0].push(cur)
        }
        return acc
    }, [[], []] as [Character[], Character[]])


    return <SpaceBetween direction="vertical" size="s">
        <Table
            columnDefinitions={characterColumns}
            items={active}
            loadingText="Loading Characters..."
            empty="No Characters Found"
            header="Active Characters"
            loading={loading}
        />
        {deleted.length > 0 && <Table
            columnDefinitions={characterColumns}
            items={deleted}
            header="Deleted Characters"
            loading={loading}
        />}
    </SpaceBetween>
}

function Changes({ userId }: { userId: string }) {
    const [loading, setLoading] = useState(true)
    const [changes, setChanges] = useState<Change[]>([])
    const { oktaAuth: auth } = useOktaAuth()
    const { setError } = useErrorContext()

    useEffect(() => {
        getAuthenticated(`/api/account/${userId}/changes`, auth).then(res => {
            if (res) {
                setChanges(ChangesFromJson(res.data.changes))
                setLoading(false)
            }
        }, err => {
            setError(err)
            setLoading(false)
        })
    }, [])

    return <Table
        columnDefinitions={[
            { id: 'edit_date', header: 'Edit Date', cell: (row: Change) => <div>{row.edit_date?.toLocaleString()}</div> },
            { id: 'field', header: 'Field', cell: (row: Change) => <div>{row.field}</div> },
            { id: 'old_value', header: 'Old Value', cell: (row: Change) => <div>{row.old_value}</div> },
            { id: 'new_value', header: 'New Value', cell: (row: Change) => <div>{row.new_value}</div> }
        ]}
        items={changes.sort((a: Change, b: Change) => { return (b.edit_date?.getTime() || 0) - (a.edit_date?.getTime() || 0) })}
        loadingText="Loading Changes..."
        empty="No Changes Found"
        loading={loading}
    />
}

function IPs({ userId }: { userId: string }) {
    const [loading, setLoading] = useState(true)
    const [ips, setIps] = useState<IPLog[]>([])
    const { oktaAuth: auth } = useOktaAuth()
    const { setError } = useErrorContext()

    let uniqueIps = ips.reduce((acc, cur) => {
        if (!acc[cur.ip]) {
            acc[cur.ip] = 1
        } else {
            acc[cur.ip]++
        }
        return acc
    }, {} as { [key: string]: number })

    // sort unique ips by count
    uniqueIps = Object.keys(uniqueIps).sort((a, b) => uniqueIps[b] - uniqueIps[a]).reduce((acc, cur) => {
        acc[cur] = uniqueIps[cur]
        return acc
    }, {} as { [key: string]: number })

    useEffect(() => {
        getAuthenticated(`/api/account/${userId}/ips`, auth).then(res => {
            if (res) {
                setIps(IPsFromJson(res.data.ips))
                setLoading(false)
            }
        }, err => {
            setLoading(false)
            setError(err)
        })
    }, [])

    return <SpaceBetween direction="vertical" size="l">
        <div>
            <Header variant="h3">Known IPs</Header>
            <ul>
                {Object.keys(uniqueIps).map(ip => <li key={ip}><NavLink to={`/account-finder?q=${ip}&type=ip`}><Link>{ip}</Link></NavLink> [{uniqueIps[ip]}]</li>)}
            </ul>
        </div>
        <Table
            columnDefinitions={[
                { id: 'ip', header: 'IP', cell: (row: IPLog) => <NavLink to={`/account-finder?q=${row.ip}&type=ip`}><Link>{row.ip}</Link></NavLink> },
                { id: 'content_type', header: 'Content Type', cell: (row: IPLog) => <div>{row.content_type}</div> },
                { id: 'action', header: 'Action', cell: (row: IPLog) => <div>{row.action}</div> },
                { id: 'log_date', header: 'Log Date', cell: (row: IPLog) => <div>{row.log_date?.toLocaleString() || ''}</div> }
            ]}
            items={ips.sort((a: IPLog, b: IPLog) => (b.log_date?.getTime() || 0) - (a.log_date?.getTime() || 0))}
            loadingText="Loading IP Addresses..."
            empty="No IP Addresses Found"
            header={<Header variant="h3">IP Logs</Header>}
            loading={loading}
        />
    </SpaceBetween>
}


function Warnings({ userId }: { userId: string }) {
    const [loading, setLoading] = useState(true)
    const [warningData, setWarningData] = useState<WarningData | null>(null)
    const { oktaAuth: auth } = useOktaAuth()
    const { setError } = useErrorContext()

    useEffect(() => {
        getAuthenticated(`/api/account/${userId}/warnings_infractions`, auth).then(res => {
            if (res) {
                setWarningData(WarningDataFromJson(res.data))
                setLoading(false)
            }
        }, err => {
            setLoading(false)
            setError(err)
        })
    }, [])

    return loading ? <Spinner /> :
        warningData && <>
            <FieldValue field="Current Points" value={warningData.total_unexpired_points + ""} />
            <FieldValue field="Total Points" value={warningData.total_points + ""} />
            <Table
                columnDefinitions={[
                    { id: 'content_title', header: 'Content Title', cell: (row: Warning) => <div>{row.content_title}</div> },
                    { id: 'warning_date', header: 'Warning Date', cell: (row: Warning) => <div>{row.warning_date.toLocaleString()}</div> },
                    { id: 'warning_user_id', header: 'Warning User ID', cell: (row: Warning) => <div>{row.warning_user_id}</div> },
                    { id: 'title', header: 'Title', cell: (row: Warning) => <div>{row.title}</div> },
                    { id: 'notes', header: 'Notes', cell: (row: Warning) => <div>{row.notes}</div> },
                    { id: 'points', header: 'Points', cell: (row: Warning) => <div>{row.points}</div> },
                    { id: 'expiry_date', header: 'Expiry Date', cell: (row: Warning) => <div>{row.expiry_date?.toLocaleString() || ''}</div> }
                ]}
                items={warningData.warnings.sort((a, b) => b.warning_date.getTime() - a.warning_date.getTime())}
                loadingText="Loading Warnings/Infractions..."
                empty="No Warnings/Infractions Found"
                loading={loading}
            />
        </>
}

function SupportTickets({ userId }: { userId: string }) {
    const [loading, setLoading] = useState(true)
    const [tickets, setTickets] = useState<SupportTicket[]>([])
    const { oktaAuth: auth } = useOktaAuth()
    const { setError } = useErrorContext()

    useEffect(() => {
        getAuthenticated(`/api/account/${userId}/support_tickets`, auth).then(res => {
            if (res) {
                setTickets(SupportTicketsFromJson(res.data.support_tickets))
                setLoading(false)
            }
        }, err => {
            setLoading(false)
            setError(err)
        })
    }, [])

    return <Table
        columnDefinitions={[
            { id: 'ticket_ref', header: 'Ticket Ref', cell: (row: SupportTicket) => <div>{row.ticket_ref}</div> },
            { id: 'title', header: 'Title', cell: (row: SupportTicket) => <div>{row.title}</div> },
            { id: 'status', header: 'Ticket Status', cell: (row: SupportTicket) => <div>{row.status}</div> },
            { id: 'start_date', header: 'Start Date', cell: (row: SupportTicket) => <div>{row.start_date?.toLocaleString() || ''}</div> },
            { id: 'link', header: 'Link', cell: (row: SupportTicket) => <Link href={`https://swgr.org/support/${row.ticket_id}`} target="_blank">View Ticket</Link> }
        ]}
        items={tickets.sort((a: SupportTicket, b: SupportTicket) => (b.start_date?.getTime() || 0) - (a.start_date?.getTime() || 0))}
        loadingText="Loading Support Tickets..."
        empty="No Support Tickets Found"
        loading={loading}
    />
}


function FortitudeGrants({ username }: { username: string }) {
    const [loading, setLoading] = useState(true)
    const [grants, setGrants] = useState<Grant[]>([])
    const { oktaAuth: auth } = useOktaAuth()
    const { setError } = useErrorContext()

    useEffect(() => {
        getAuthenticated(`/api/fortitude/list`, auth, { params: { username } }).then(res => {
            if (res) {
                setGrants(GrantsFromJson(res.data))
                setLoading(false)
            }
        }, err => {
            setLoading(false)
            setError(err)
        })
    }, [])

    return <FortitudeGrantsTable grants={grants} loading={loading} />
}