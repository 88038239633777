import { Box, Header, Link, Pagination, SpaceBetween, TextFilter } from "@cloudscape-design/components";
import Table, { TableProps } from "@cloudscape-design/components/table";
import { useEffect, useState } from "react";
import getAuthenticated from "../request";
import { useOktaAuth } from '@okta/okta-react';

import PieChart from "@cloudscape-design/components/pie-chart";
import Button from "@cloudscape-design/components/button";
import { JediEnumValue, JediStatEnumValues, JediStats, JediStatsFromJson } from "../interfaces/Stats";
import { useErrorContext } from "../ErrorContext";
import { NavLink } from "react-router-dom";

export default function Metrics() {
    const [data, setData] = useState<{[key: string]: number} >({})
    const [loading, setLoading] = useState(true)
    const { oktaAuth:auth } = useOktaAuth()
    const setError = useErrorContext().setError
        
    useEffect(() => {
        getAuthenticated('/api/account/verifications', auth).then((response) => {
            setLoading(false)
            setData(response.data);
        }, err => { 
            setLoading(false)
            setError(err.message) 
        });
    }, [])

    if(loading) {
        return <PieChart statusType="loading" loadingText={`Loading Stats`} data={[]}/>
    } else {
        return <Chart data={data} />
    }

}

function Chart({data}: {data: { [key: string]: number }}) {


    const segments = (Object.keys(data) as Array<keyof typeof data>).map(key => { 
        let title = key as string
        if(title === 'verified') title = 'Verified'
        if(title === 'required') title = 'Required'
        if(title.includes('_')) title = title.split('_').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')
        return {
        title: title,
        value: data[key]
    }})

    return <SpaceBetween
        direction="horizontal"
        size="m"
        >
        <PieChart
            data={segments}
            detailPopoverContent={(datum, sum) => [
                { key: "Count", value: datum.value },
                {
                    key: "Percentage",
                    value: `${((datum.value / sum) * 100).toFixed(
                        0
                    )}%`
                },
                { key: "Game enum", value: (Object.keys(JediStatEnumValues) as unknown as Array<keyof typeof JediStatEnumValues>).find(key => JediStatEnumValues[key] === datum.title)?.toString() || 'Unknown' }
            ]}
            segmentDescription={(datum, sum) =>
                `${datum.value} players, ${(
                    (datum.value / sum) *
                    100
                ).toFixed(0)}%`
            }
            i18nStrings={{
                detailsValue: "Value",
                detailsPercentage: "Percentage",
                filterLabel: "Filter displayed data",
                filterPlaceholder: "Filter data",
                filterSelectedAriaLabel: "selected",
                detailPopoverDismissAriaLabel: "Dismiss",
                legendAriaLabel: "Legend",
                chartAriaRoleDescription: "pie chart",
                segmentAriaRoleDescription: "segment"
            }}
            ariaDescription="Pie chart showing how many accounts have certain verification statuses."
            ariaLabel="Pie chart"
            errorText="Error loading data."
            loadingText="Loading chart"
            recoveryText="Retry"
            variant="donut"
            hideFilter
            innerMetricDescription="Total Accounts"
            innerMetricValue={segments.reduce((sum, segment) => sum + segment.value, 0) + ''}
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No data available</b>
                    <Box variant="p" color="inherit">
                        There is no data available
                    </Box>
                </Box>
            }
            noMatch={
                <Box textAlign="center" color="inherit">
                    <b>No matching data</b>
                    <Box variant="p" color="inherit">
                        There is no matching data to display
                    </Box>
                    <Button>Clear filter</Button>
                </Box>
            }
            // highlightedSegment={highlighted ? segments.find(s => s.title === highlighted) : null}
        />
    </SpaceBetween>
}