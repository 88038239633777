import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import './App.scss';
import Navigation from './Navigation';
import { applyMode, Mode } from '@cloudscape-design/global-styles';
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import DataTables from "./game-data/DataTables";
import StringSearch from "./game-data/StringSearch";
import AccountFinder from "./user-management/AccountFinder";
import Menu from './Menu';
import { Alert, Container, ContentLayout, Header, SpaceBetween } from '@cloudscape-design/components';
import AccountView from './user-management/AccountView';
import { ErrorContext, useErrorContext } from './ErrorContext';
import GrantFortitude from './user-management/GrantFortitude';
import PendingFortitude from './user-management/PendingFortitude';
import CharacterViewer from './characters/CharacterViewer';
import Metrics from './game-data/Metrics';
import AdminCommands from './admin-commands.mdx'
import ObjectContainment from './game-data/ObjectContainment';
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import Secured from './Secured';
import BaseliningAdmin from './support/BaseliningAdmin';
import Baselining from './support/Baselining';
import ObjectLookup from './game-data/ObjectLookup';
import PatchNoteTool from './support/PatchNoteTool';
import MultiAccountReport from './user-management/MultiAccountReport';

const oktaAuth = new OktaAuth({
  issuer: 'https://omitv.okta.com/',
  clientId: '0oa4cbu8ygWqZTqea697',
  redirectUri: window.location.origin + '/login/callback',
});

function App() {
  const currentMode = localStorage.getItem('mode') ? localStorage.getItem('mode') : 'dark';
  applyMode(currentMode === 'dark' ? Mode.Dark : Mode.Light);


  return (
    <div className="App">
      <BrowserRouter>
        <RoutedApp />
      </BrowserRouter>
    </div>
  );
}

function RoutedApp() {
  const navigate = useNavigate();

  const restoreOriginalUri: RestoreOriginalUriFunction = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/login/callback" element={<LoginCallback />} />
      </Routes>
      <Secured>
        <header>
          <nav>
            <Navigation />
          </nav>
        </header>
        <main >
          <Routes>
            <Route index path="/" element={<Menu />} />
            <Route path="/datatable-viewer" element={<Content title="Datatable Viewer" element={<DataTables />} />} />
            <Route path="/string-search" element={<Content title="String Search" element={<StringSearch />} />} />
            <Route path="/account-finder" element={<Content title="Account Finder" element={<AccountFinder />} />} />
            <Route path="/account-detail/:userId" element={<Content title="Account Detail" element={<AccountView />} />} />
            <Route path="/character-viewer" element={<Content title="Character Detail" element={<CharacterViewer />} />} />
            <Route path="/character-viewer/:character" element={<Content title="Character Detail" element={<CharacterViewer />} />} />
            <Route path="/fortitude-grant" element={<Content title="Grant Fortitude Tokens" element={<GrantFortitude />} />} />
            <Route path="/fortitude-pending" element={<Content title="Pending Fortitude Grants" element={<PendingFortitude />} />} />
            <Route path="/metrics" element={<Content title="Metrics" element={<Metrics />} />} />
            <Route path="/admin-commands" element={<Content title="Admin Commands" element={<AdminCommands />} />} />
            <Route path="/object-containment" element={<Content title="Object Containment" element={<ObjectContainment />} />} />
            <Route path="/object-lookup" element={<Content title="Object Lookup" element={<ObjectLookup />} />} />
            <Route path="/baselining-admin" element={<Content title="Manage Actors" element={<BaseliningAdmin />} />} />
            <Route path="/baselining-actor" element={<Content title="Command Baselining" element={<Baselining />} />} />
            <Route path="/patch-notes-tool" element={<Content title="Patch Notes Tool" element={<PatchNoteTool />} />} />
            <Route path="/mas-report" element={<Content title="Multi Account Report" element={<MultiAccountReport />} />} />
          </Routes>
        </main>
      </Secured>
    </Security>
  )
}

function Content({ title, element }: { title: string, element: ReactNode }) {
  const [error, setError] = useState<any>('')

  useEffect(() => {
    document.title = `${title} - The Maw`
  }, [title])

  return (
    <ErrorContext.Provider value={{ error: '', setError }}>
      <ContentLayout header={<SpaceBetween direction='vertical' size='m'>
        <Header variant='h1'>{title}</Header>
        {error && <Alert type='error' dismissible onDismiss={() => { setError('') }}>{error.message ? error.message : error}</Alert>}
      </SpaceBetween>}>
        <Container>
          {element}
        </Container>
      </ContentLayout>
    </ErrorContext.Provider>
  )
}

export default App;
